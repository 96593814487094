import React, {useEffect} from 'react';
import {connect} from 'react-redux';
import {Link, RouteComponentProps} from 'react-router-dom';
import {Button, Col, Row} from 'reactstrap';
import {TextFormat} from 'react-jhipster';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';

import {IRootState} from 'app/shared/reducers';
import {getEntity} from './interest-expression.reducer';
import {APP_LOCAL_DATE_FORMAT} from 'app/config/constants';

export interface IInterestExpressionDetailProps extends StateProps, DispatchProps, RouteComponentProps<{ id: string }> {}

export const InterestExpressionDetail = (props: IInterestExpressionDetailProps) => {
  useEffect(() => {
    props.getEntity(props.match.params.id);
  }, []);

  const { interestExpressionEntity } = props;
  return (
    <Row>
      <Col md="8">
        <h2>
          InterestExpression [<b>{interestExpressionEntity.id}</b>]
        </h2>
        <dl className="jh-entity-details">
          <dt>
            <span id="name">Name</span>
          </dt>
          <dd>{interestExpressionEntity.name}</dd>
          <dt>
            <span id="email">Email</span>
          </dt>
          <dd>{interestExpressionEntity.email}</dd>
          <dt>
            <span id="date">Date</span>
          </dt>
          <dd>
            <TextFormat value={interestExpressionEntity.date} type="date" format={APP_LOCAL_DATE_FORMAT} />
          </dd>
        </dl>
        <Button tag={Link} to="/interest-expression" replace color="info">
          <FontAwesomeIcon icon="arrow-left" /> <span className="d-none d-md-inline">Back</span>
        </Button>
      </Col>
    </Row>
  );
};

const mapStateToProps = ({ interestExpression }: IRootState) => ({
  interestExpressionEntity: interestExpression.entity
});

const mapDispatchToProps = { getEntity };

type StateProps = ReturnType<typeof mapStateToProps>;
type DispatchProps = typeof mapDispatchToProps;

export default connect(mapStateToProps, mapDispatchToProps)(InterestExpressionDetail);
