import React from 'react';
import { Switch } from 'react-router-dom';
// eslint-disable-next-line @typescript-eslint/no-unused-vars
import ErrorBoundaryRoute from 'app/shared/error/error-boundary-route';

import Client from './client';
import Therapist from './therapist';
import Appointment from './appointment';
import VerificationDocument from './verification-document';
import Price from './price';
import BlogPost from './blog-post';
import PromoCode from './promo-code';
import InterestExpression from './interest-expression';
import MoodTips from './mood-tips';
import MoodTracker from './mood-tracker';
import Diagnostic from './diagnostic';
import Notifications from './notifications';
/* jhipster-needle-add-route-import - JHipster will add routes here */

const Routes = ({ match }) => (
  <div>
    <Switch>
      {/* prettier-ignore */}
      <ErrorBoundaryRoute path={`${match.url}client`} component={Client} />
      <ErrorBoundaryRoute path={`${match.url}therapist`} component={Therapist} />
      <ErrorBoundaryRoute path={`${match.url}appointment`} component={Appointment} />
      <ErrorBoundaryRoute path={`${match.url}verification-document`} component={VerificationDocument} />
      <ErrorBoundaryRoute path={`${match.url}price`} component={Price} />
      <ErrorBoundaryRoute path={`${match.url}blog-post`} component={BlogPost} />
      <ErrorBoundaryRoute path={`${match.url}promo-code`} component={PromoCode} />
      <ErrorBoundaryRoute path={`${match.url}interest-expression`} component={InterestExpression} />
      <ErrorBoundaryRoute path={`${match.url}mood-tips`} component={MoodTips} />
      <ErrorBoundaryRoute path={`${match.url}mood-tracker`} component={MoodTracker} />
      <ErrorBoundaryRoute path={`${match.url}diagnostic`} component={Diagnostic} />
      <ErrorBoundaryRoute path={`${match.url}notifications`} component={Notifications} />
      {/* jhipster-needle-add-route-path - JHipster will add routes here */}
    </Switch>
  </div>
);

export default Routes;
