import { Moment } from 'moment';

export interface IPromoCode {
  id?: number;
  code?: string;
  useTime?: number;
  discountPercent?: number;
  createdDate?: Moment;
  updatedDate?: Moment;
}

export const defaultValue: Readonly<IPromoCode> = {};
