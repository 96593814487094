import React from 'react';
import {  Row, Col, Label } from 'reactstrap';
import { AvGroup,  AvField } from 'availity-reactstrap-validation';

export interface IDiagnosticQuestionsProps {
	id,
	questionName,
	diagnosticQuestionOptions
	
}

class DiagnosticQuestionsUpdate extends React.Component<IDiagnosticQuestionsProps, {}> {
	
	state = {
		questionId: this.props.id,
		questionName: this.props.questionName,
		options: this.props.diagnosticQuestionOptions
	};
	
	formRef = React.createRef();
	
	componentDidMount() {
		
	}

    onQuestionTextChange = (e) => {
		this.setState({ ...this.state, questionName: e.target.value});
	}

	onOptionTextChange = (e) => {
		
		const tempOptions = JSON.parse(JSON.stringify(this.state.options));
		const textChangedOptionInd = this.state.options.findIndex( index => index.id+"optionId" === e.target.id);
		const textChangedOption = { ...this.state.options[textChangedOptionInd]};
		textChangedOption["optionName"] = e.target.value;
		tempOptions[textChangedOptionInd] = textChangedOption;
		this.setState({... this.state, options: tempOptions});
	}
	
	onScoreTextChange = (e) => {
		
		const tempOptions = JSON.parse(JSON.stringify(this.state.options));
		const textChangedOptionInd = this.state.options.findIndex( index => index.id+"scoreId" === e.target.id);
		const textChangedOption = { ...this.state.options[textChangedOptionInd]};
		textChangedOption["optionScore"] = e.target.value;
		tempOptions[textChangedOptionInd] = textChangedOption;
		this.setState({... this.state, options: tempOptions});
	}

    getValidations = (index) => {
		if(index >= 0 && index < 2) {
		  return {required: {value: true, errorMessage: 'This field is required.'}}
		} else {
			return  {required: {value: false}};
		}
	}

	render() {
		return (
    <div>
      <Row className="justify-content-center">
        <Col md="12">
	         	  <AvGroup key={this.state.questionId+"question"}>
	                <Label id="questionLabel" for="diagnostic-title">
	                  Question:
	                </Label>
	                <AvField key={this.state.questionId+"questionField"} id={this.state.questionId+"question-title"} type="text" name={this.state.questionId+"question"} value={this.state.questionName} onChange={this.onQuestionTextChange} validate={{
	                    required: {value: true, errorMessage: 'This field is required.'}
	                  }} />
	              </AvGroup>
				  <Row className="justify-content-center">
				  	<Col md="8">
					  {
						this.state.options.map((item, index) => 
							
							<AvGroup key={item.id}>
								<Row className="justify-content-center">
									<Col md="9">
										<AvField id={item.id+"optionId"} type="text" name={item.id+"option"} placeholder="Option" value={item.optionName} validate={this.getValidations(index)} onChange={this.onOptionTextChange} />
									</Col>
									<Col md="3">
										<AvField id={item.id+"scoreId"} type="number" name={item.id+"score"} placeholder="Score" value={item.optionScore} validate={this.getValidations(index)} onChange={this.onScoreTextChange} />
									</Col>
								</Row>
							</AvGroup>
						)}
					</Col>
				  </Row>
        </Col>
      </Row>
    </div>
  );
	}
	
}

export default DiagnosticQuestionsUpdate;
