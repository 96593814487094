export interface IBlogPost {
  id?: number;
  title?: string;
  image?: string;
  url?: string;
  createdDate?: string;
  blogType?: string;
  video?: string;
  blogText?: string;
}

export const defaultValue: Readonly<IBlogPost> = {};
