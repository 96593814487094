import React, { useState } from 'react';
import { connect } from 'react-redux';
import { Button } from 'reactstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { TextFormat } from 'react-jhipster';

import { IRootState } from 'app/shared/reducers';
import { IVerificationDocument } from 'app/shared/model/verification-document.model';
import { APP_LOCAL_DATE_FORMAT } from 'app/config/constants';

import { setValidityDates, rejectVerificationDocument, validateVerificationDocument } from './therapist-verification-document.reducer';

export interface IDocumentEntityProps extends StateProps, DispatchProps {
  therapistDocument: IVerificationDocument;
}

export const DocumentEntity = (props: IDocumentEntityProps) => {
  const { therapistDocument, updating } = props;

  const [isEditMode, setEditMode] = useState(false);
  const [documentValidityDates, setDocumentValidityDates] = useState({
    validityFrom: (therapistDocument.validityFrom as unknown) as string,
    validityTo: (therapistDocument.validityTo as unknown) as string
  });

  const saveEntity = () => {
    const { validityFrom, validityTo } = documentValidityDates;
    if (!validityFrom || !validityTo) return;
    const entity = {
      id: therapistDocument.id,
      therapistId: therapistDocument.therapistId,
      validityFrom,
      validityTo
    };
    props.setValidityDates(entity);
  };

  const validateDocument = () => props.validateVerificationDocument(therapistDocument);
  const rejectDocument = () => props.rejectVerificationDocument(therapistDocument);

  const renderEditActions = () => {
    if (isEditMode)
      return (
        <>
          <Button color="danger" size="sm" onClick={() => setEditMode(false)}>
            <FontAwesomeIcon icon="ban" /> <span className="d-none d-md-inline">Cancel</span>
          </Button>
          &nbsp;&nbsp;
          <Button color="primary" disabled={updating} size="sm" onClick={saveEntity}>
            <FontAwesomeIcon icon="save" /> <span className="d-none d-md-inline">Save</span>
          </Button>
        </>
      );
    return (
      <Button color="primary" size="sm" onClick={() => setEditMode(true)}>
        <FontAwesomeIcon icon="pencil-alt" /> <span className="d-none d-md-inline">Edit</span>
      </Button>
    );
  };

  return (
    <tr>
      <td>{therapistDocument.id}</td>
      <td>{therapistDocument.title}</td>
      <td>
        {isEditMode ? (
          <input
            type="date"
            value={documentValidityDates.validityFrom || ''}
            onChange={e => setDocumentValidityDates({ validityFrom: e.target.value, validityTo: documentValidityDates.validityTo })}
          />
        ) : (
          <TextFormat type="date" value={therapistDocument.validityFrom} format={APP_LOCAL_DATE_FORMAT} />
        )}
      </td>
      <td>
        {isEditMode ? (
          <input
            type="date"
            value={documentValidityDates.validityTo || ''}
            onChange={e => setDocumentValidityDates({ validityFrom: documentValidityDates.validityFrom, validityTo: e.target.value })}
          />
        ) : (
          <TextFormat type="date" value={therapistDocument.validityTo} format={APP_LOCAL_DATE_FORMAT} />
        )}
      </td>
      <td>{`${therapistDocument.isValid}`}</td>
      <td>{`${therapistDocument.isCurrent}`}</td>
      <td>{`${therapistDocument.isExpiresInAFewDays}`}</td>
      <td>{therapistDocument.documentType}</td>
      {therapistDocument.url && (
        <td>
          <a href={therapistDocument.url} rel="noopener noreferrer" target="_blank">
            Download
          </a>
        </td>
      )}
      <td className="text-right">
        <div className="btn-group flex-btn-group-container">{renderEditActions()}</div>
      </td>
      <td className="text-center">
        <div className="btn-group flex-btn-group-container">
          <Button color="success" disabled={therapistDocument.isValid === true || updating} onClick={validateDocument} size="sm">
            <FontAwesomeIcon icon="check-circle" /> <span className="d-none d-md-inline">Validate</span>
          </Button>
        </div>
      </td>  
      <td className="text-center">
        <div className="btn-group flex-btn-group-container">
          <Button color="danger" disabled={therapistDocument.isValid === false || updating} onClick={rejectDocument} size="sm">
            <FontAwesomeIcon icon="times-circle" /> <span className="d-none d-md-inline">Reject</span>
          </Button>
        </div>
      </td>
    </tr>
  );
};

const mapStateToProps = ({ therapistVerificationDocument }: IRootState) => ({
  updating: therapistVerificationDocument.updating
});

const mapDispatchToProps = {
  setValidityDates,
  validateVerificationDocument,
  rejectVerificationDocument
};

type StateProps = ReturnType<typeof mapStateToProps>;
type DispatchProps = typeof mapDispatchToProps;

export default connect(mapStateToProps, mapDispatchToProps)(DocumentEntity);
