import React, {useEffect} from 'react';
import {connect} from 'react-redux';
import {Link, RouteComponentProps} from 'react-router-dom';
import {Button, Col, Row} from 'reactstrap';
import {TextFormat} from 'react-jhipster';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';

import {IRootState} from 'app/shared/reducers';
import {getEntity} from './promo-code.reducer';
import {APP_DATE_FORMAT} from 'app/config/constants';

export interface IPromoCodeDetailProps extends StateProps, DispatchProps, RouteComponentProps<{ id: string }> {}

export const PromoCodeDetail = (props: IPromoCodeDetailProps) => {
  useEffect(() => {
    props.getEntity(props.match.params.id);
  }, []);

  const { promoCodeEntity } = props;
  return (
    <Row>
      <Col md="8">
        <h2>
          PromoCode [<b>{promoCodeEntity.id}</b>]
        </h2>
        <dl className="jh-entity-details">
          <dt>
            <span id="code">Code</span>
          </dt>
          <dd>{promoCodeEntity.code}</dd>
          <dt>
            <span id="useTime">Use Time</span>
          </dt>
          <dd>{promoCodeEntity.useTime}</dd>
		  <dt>
            <span id="useTime">Discount Percentage</span>
          </dt>
          <dd>{promoCodeEntity.discountPercent}</dd>
          <dt>
            <span id="createdDate">Created Date</span>
          </dt>
          <dd>
            {promoCodeEntity.createdDate ? <TextFormat value={promoCodeEntity.createdDate} type="date" format={APP_DATE_FORMAT} /> : null}
          </dd>
          <dt>
            <span id="updatedDate">Updated Date</span>
          </dt>
          <dd>
            {promoCodeEntity.updatedDate ? <TextFormat value={promoCodeEntity.updatedDate} type="date" format={APP_DATE_FORMAT} /> : null}
          </dd>
        </dl>
        <Button tag={Link} to="/promo-code" replace color="info">
          <FontAwesomeIcon icon="arrow-left" /> <span className="d-none d-md-inline">Back</span>
        </Button>
        &nbsp;
        <Button tag={Link} to={`/promo-code/${promoCodeEntity.id}/edit`} replace color="primary">
          <FontAwesomeIcon icon="pencil-alt" /> <span className="d-none d-md-inline">Edit</span>
        </Button>
      </Col>
    </Row>
  );
};

const mapStateToProps = ({ promoCode }: IRootState) => ({
  promoCodeEntity: promoCode.entity,
});

const mapDispatchToProps = { getEntity };

type StateProps = ReturnType<typeof mapStateToProps>;
type DispatchProps = typeof mapDispatchToProps;

export default connect(mapStateToProps, mapDispatchToProps)(PromoCodeDetail);
