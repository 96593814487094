import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { Link, RouteComponentProps } from 'react-router-dom';
import { Button, Table, Row, Badge, Col, Label } from 'reactstrap';
import { AvForm, AvGroup, AvInput, AvField } from 'availity-reactstrap-validation';
import { JhiItemCount, JhiPagination, getSortState } from 'react-jhipster';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { IRootState } from 'app/shared/reducers';

import { getEntity, updateEntity, createEntity, reset } from './notifications.reducer';
import { getEntities } from '../client/client.reducer';
import { CLIENTS_PER_PAGE } from 'app/shared/util/pagination.constants';
export interface INotificationsUpdateProps extends StateProps, DispatchProps, RouteComponentProps<{ id: string }> { }

export const NotificationsUpdate = (props: INotificationsUpdateProps) => {
  const [isNew, setIsNew] = useState(!props.match.params || !props.match.params.id);

  const { notificationsEntity, loading, updating } = props;
  const [selectedClients, setSelectedClients] = useState<number[]>([]);
  const [selectAll, setSelectAll] = useState(false);
  const [validationError, setValidationError] = useState(false);
  const handleClose = () => {
    props.history.push('/notifications' + props.location.search);
  };

  const [paginationState, setPaginationState] = useState(getSortState(props.location, CLIENTS_PER_PAGE));

  const getAllEntities = () => {
    props.getEntities(paginationState.activePage - 1, paginationState.itemsPerPage, `${paginationState.sort},${paginationState.order}`);
  };

  const sortEntities = () => {
    getAllEntities();
    props.history.push(
      `${props.location.pathname}?page=${paginationState.activePage}&sort=${paginationState.sort},${paginationState.order}`
    );
  };

 useEffect(() => {
    sortEntities();
  }, [paginationState.activePage, paginationState.order, paginationState.sort]);


  const sort = p => () => {
    setPaginationState({
      ...paginationState,
      order: paginationState.order === 'asc' ? 'desc' : 'asc',
      sort: p
    });
  };

   const handlePagination = currentPage =>
     setPaginationState({
       ...paginationState,
       activePage: currentPage
     });
   useEffect(() => {
     if (isNew) {
       props.reset();
     } else {
       props.getEntity(props.match.params.id);
     }
   }, []);


  useEffect(() => {
    if (props.updateSuccess) {
      handleClose();
    }
  }, [props.updateSuccess]);

  const saveEntity = (event, errors, values) => {
    if (errors.length === 0 && selectedClients.length > 0) {
      setValidationError(false);
      const entity = {
        ...notificationsEntity,
        ...values,
        selectedClients
      };

      if (isNew) {
        props.createEntity(entity);
      } else {
        props.updateEntity(entity);
      }
    } else {
      setValidationError(true);
    }
  };
  const { clientList, match, totalItems } = props;
  const [filters, setFilters] = useState({
    id: '',
    firstName: '',
    email: '',
  });
  const handleFilterChange = (field, value) => {
    setFilters({ ...filters, [field]: value });
  };

  const handleSelectClient = (clientId) => {
    const updatedSelectedClients = (selectedClients || []).includes(clientId)
      ? (selectedClients || []).filter((id) => id !== clientId)
      : [...(selectedClients || []), clientId];

    setSelectedClients(updatedSelectedClients);
    setValidationError(updatedSelectedClients.length === 0);
  };

  const filteredClients = (clientList || []).filter(
    (client) =>
      client &&

      client.id.toString().includes((filters.id || '').toLowerCase().trim()) &&
      (client.firstName || '').toLowerCase().includes((filters.firstName || '').toLowerCase().trim()) &&
      client.email.toLowerCase().includes((filters.email || '').toLowerCase().trim())
  );

  const handleSelectAll = () => {
    setSelectAll(!selectAll);
    setSelectedClients(selectAll ? [] : filteredClients.map((client) => (client.user ? client.user.id : client.id)));
  };

  return (

    <div>
      <Row className="justify-content-center">
        <Col md="8">
          <h2 id="marpeWellbeingApp.notifications.home.createOrEditLabel">Create or edit a Notifications</h2>
        </Col>
      </Row>
      <Row className="justify-content-center">
        <Col md="9">
          {loading ? (
            <p>Loading...</p>
          ) : (
            <AvForm model={isNew ? {} : notificationsEntity} onSubmit={saveEntity}>

              {!isNew ? (
                <AvGroup>
                  <Label for="notifications-id">ID</Label>
                  <AvInput id="notifications-id" type="text" className="form-control" name="id" required readOnly />
                </AvGroup>
              ) : null}

              <AvGroup>
                <Label id="titleLabel" for="notifications-title">
                  Title
                </Label>
                <AvField
                  id="notifications-title"
                  type="text"
                  name="title"
                  validate={{
                    required: { value: true, errorMessage: 'This field is required.' }
                  }}
                />
              </AvGroup>

              <AvGroup>
                <Label id="descriptionLabel" for="notifications-description">
                  Description
                </Label>
                <AvField
                  id="notifications-description"
                  type="text"
                  name="description"
                  validate={{
                    required: { value: true, errorMessage: 'This field is required.' }
                  }}
                />
              </AvGroup>
               <Button tag={Link} id="cancel-save" to="/notifications" replace color="info">
                                          <FontAwesomeIcon icon="arrow-left" />
                                          &nbsp;
                                          <span className="d-none d-md-inline">Back</span>
                                        </Button>
                                        &nbsp;
                                        <Button color="primary" id="save-entity" type="submit" disabled={updating}>
                                          <FontAwesomeIcon icon="save" />
                                          &nbsp; Save
                                        </Button>
              <div>
                <div className="table-responsive">
                  {clientList && clientList.length > 0 ? (
                    <Table responsive>
                      <thead>
                        <tr>
                          <th className="hand">
                            <input
                              type="text"
                              value={filters.id}
                              onChange={(e) => handleFilterChange('id', e.target.value)}
                              placeholder="Filter ID"
                            />
                            ID <FontAwesomeIcon icon="sort" onClick={sort('id')} />

                          </th>
                          <th className="hand" >
                            <input
                              type="text"
                              value={filters.firstName}
                              onChange={(e) => handleFilterChange('firstName', e.target.value)}
                              placeholder="Filter First Name"
                            />
                            First Name <FontAwesomeIcon icon="sort" onClick={sort('firstName')} />
                          </th>
                          <th className="hand" >
                            <input
                              type="text"
                              value={filters.email}
                              onChange={(e) => handleFilterChange('email', e.target.value)}
                              placeholder="Filter Email"
                            />

                            Email <FontAwesomeIcon icon="sort" onClick={sort('email')} />
                          </th>
                          <th>
                            <input
                              type="checkbox"
                              checked={selectAll}
                              onChange={handleSelectAll}
                            />
                            Select All
                          </th>
                          <th />
                        </tr>
                      </thead>
                      <tbody>
                        {filteredClients.map((client, i) => (
                          <tr key={`entity-${i}`}>
                            <td>
                              <Button tag={Link} to={`${match.url}/${client.id}`} color="link" size="sm">
                                {client.id}
                              </Button>

                            </td>
                            <td>{client.firstName}</td>
                            <td>{client.email}</td>
                            <td>
                              <input
                                type="checkbox"
                                checked={selectAll || (client.user ? selectedClients.includes(client.user.id) : selectedClients.includes(client.id))}
                                onChange={() => handleSelectClient(client.user ? client.user.id : client.id)}
                              />
                            </td>
                          </tr>
                        ))}

                      </tbody>
                    </Table>
                  ) : (
                    !loading && <div className="alert alert-warning">No Clients found</div>
                  )}
                </div>
              </div>
              {validationError && (
                <div className="alert alert-danger">
                  At least one client must be selected.
                </div>
              )}

            </AvForm>
          )}
        </Col>
      </Row>
      <div className={clientList && clientList.length > 0 ? '' : 'd-none'}>
              <Row className="justify-content-center">
                <JhiItemCount page={paginationState.activePage} total={totalItems} itemsPerPage={paginationState.itemsPerPage} />
              </Row>
              <Row className="justify-content-center">
                <JhiPagination
                  activePage={paginationState.activePage}
                  onSelect={handlePagination}
                  maxButtons={3}
                  itemsPerPage={paginationState.itemsPerPage}
                  totalItems={props.totalItems}
                />
              </Row>
            </div>
    </div>
  );
};

const mapStateToProps = (storeState: IRootState) => ({
  notificationsEntity: storeState.notifications.entity,
  loading: storeState.notifications.loading,
  updating: storeState.notifications.updating,
  updateSuccess: storeState.notifications.updateSuccess,
  totalItems: storeState.client.totalItems,
  clientList: storeState.client.entities
});

const mapDispatchToProps = {
  getEntity,
  updateEntity,
  createEntity,
  reset,
  getEntities
};


type StateProps = ReturnType<typeof mapStateToProps>;
type DispatchProps = typeof mapDispatchToProps;



export default connect(mapStateToProps, mapDispatchToProps)(NotificationsUpdate);
