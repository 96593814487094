import { Moment } from 'moment';
import { IAppointment } from 'app/shared/model/appointment.model';
import { TherapistStatus } from 'app/shared/model/enumerations/therapist-status.model';
import { JobRole } from 'app/shared/model/enumerations/job-role.model';

export interface ITherapist {
  id?: number;
  firstName?: string;
  lastName?: string;
  email?: string;
  address1?: string;
  address2?: string;
  postalCode?: string;
  city?: string;
  state?: string;
  dateOfBirth?: Moment;
  description?: string;
  specializations?: string;
  imageS3Key?: string;
  status?: TherapistStatus;
  activationKey?: string;
  jobRole?: JobRole;
  rating?: number;
  phone?: string;
  appointments?: IAppointment[];
  accountName?: string;
  accountNumber?: string;
  sortCode?: string;
}

export const defaultValue: Readonly<ITherapist> = {};
