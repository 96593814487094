import { combineReducers } from 'redux';
import { loadingBarReducer as loadingBar } from 'react-redux-loading-bar';

import authentication, { AuthenticationState } from './authentication';
import applicationProfile, { ApplicationProfileState } from './application-profile';

import administration, { AdministrationState } from 'app/modules/administration/administration.reducer';
import userManagement, { UserManagementState } from 'app/modules/administration/user-management/user-management.reducer';
import register, { RegisterState } from 'app/modules/account/register/register.reducer';
import activate, { ActivateState } from 'app/modules/account/activate/activate.reducer';
import password, { PasswordState } from 'app/modules/account/password/password.reducer';
import settings, { SettingsState } from 'app/modules/account/settings/settings.reducer';
import passwordReset, { PasswordResetState } from 'app/modules/account/password-reset/password-reset.reducer';
import therapistVerificationDocument, {
  TherapistVerificationDocumentState
} from 'app/modules/approval/therapist-verification-document/therapist-verification-document.reducer';
// prettier-ignore
import client, {ClientState} from 'app/entities/client/client.reducer';
// prettier-ignore
import therapist, {TherapistState} from 'app/entities/therapist/therapist.reducer';
// prettier-ignore
import appointment, {AppointmentState} from 'app/entities/appointment/appointment.reducer';
// prettier-ignore
import verificationDocument, {VerificationDocumentState} from 'app/entities/verification-document/verification-document.reducer';
// prettier-ignore
import price, {PriceState} from 'app/entities/price/price.reducer';
// prettier-ignore
import blogPost, {BlogPostState} from 'app/entities/blog-post/blog-post.reducer';
// prettier-ignore
import promoCode, {PromoCodeState} from 'app/entities/promo-code/promo-code.reducer';
// prettier-ignore
import interestExpression, {InterestExpressionState} from 'app/entities/interest-expression/interest-expression.reducer';

// prettier-ignore
import moodTips, {
  MoodTipsState
} from 'app/entities/mood-tips/mood-tips.reducer';

// prettier-ignore
import moodTracker, {
  MoodTrackerState
} from 'app/entities/mood-tracker/mood-tracker.reducer';

// prettier-ignore
import diagnostic, {
  DiagnosticState
} from 'app/entities/diagnostic/diagnostic.reducer';
// prettier-ignore
import notifications, {
  NotificationsState
} from 'app/entities/notifications/notifications.reducer';
/* jhipster-needle-add-reducer-import - JHipster will add reducer here */

export interface IRootState {
  readonly authentication: AuthenticationState;
  readonly applicationProfile: ApplicationProfileState;
  readonly administration: AdministrationState;
  readonly userManagement: UserManagementState;
  readonly register: RegisterState;
  readonly activate: ActivateState;
  readonly passwordReset: PasswordResetState;
  readonly password: PasswordState;
  readonly settings: SettingsState;
  readonly client: ClientState;
  readonly therapist: TherapistState;
  readonly therapistVerificationDocument: TherapistVerificationDocumentState;
  readonly appointment: AppointmentState;
  readonly verificationDocument: VerificationDocumentState;
  readonly price: PriceState;
  readonly blogPost: BlogPostState;
  readonly promoCode: PromoCodeState;
  readonly interestExpression: InterestExpressionState;
  readonly moodTips: MoodTipsState;
  readonly moodTracker: MoodTrackerState;
  readonly diagnostic: DiagnosticState;
  readonly notifications: NotificationsState;
  /* jhipster-needle-add-reducer-type - JHipster will add reducer type here */
  readonly loadingBar: any;
}

const rootReducer = combineReducers<IRootState>({
  authentication,
  applicationProfile,
  administration,
  userManagement,
  register,
  activate,
  passwordReset,
  password,
  settings,
  client,
  therapist,
  therapistVerificationDocument,
  appointment,
  verificationDocument,
  price,
  blogPost,
  promoCode,
  interestExpression,
  moodTips,
  moodTracker,
  diagnostic,
  notifications,
  /* jhipster-needle-add-reducer-combine - JHipster will add reducer here */
  loadingBar
});

export default rootReducer;
