import React, {useEffect} from 'react';
import {connect} from 'react-redux';
import {Link, RouteComponentProps} from 'react-router-dom';
import {Button, Col, Row} from 'reactstrap';
import {TextFormat} from 'react-jhipster';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';

import {IRootState} from 'app/shared/reducers';
import {getEntity} from './therapist.reducer';
import {APP_LOCAL_DATE_FORMAT} from 'app/config/constants';

export interface ITherapistDetailProps extends StateProps, DispatchProps, RouteComponentProps<{ id: string }> {}

export const TherapistDetail = (props: ITherapistDetailProps) => {
  useEffect(() => {
    props.getEntity(props.match.params.id);
  }, []);

  const { therapistEntity } = props;
  return (
    <Row>
      <Col md="8">
        <h2>
          Therapist [<b>{therapistEntity.id}</b>]
        </h2>
        <dl className="jh-entity-details">
          <dt>
            <span id="firstName">First Name</span>
          </dt>
          <dd>{therapistEntity.firstName}</dd>
          <dt>
            <span id="lastName">Last Name</span>
          </dt>
          <dd>{therapistEntity.lastName}</dd>
          <dt>
            <span id="email">Email</span>
          </dt>
          <dd>{therapistEntity.email}</dd>
          <dt>
            <span id="address1">Address 1</span>
          </dt>
          <dd>{therapistEntity.address1}</dd>
          <dt>
            <span id="address2">Address 2</span>
          </dt>
          <dd>{therapistEntity.address2}</dd>
          <dt>
            <span id="postalCode">Postal Code</span>
          </dt>
          <dd>{therapistEntity.postalCode}</dd>
          <dt>
            <span id="city">City</span>
          </dt>
          <dd>{therapistEntity.city}</dd>
          <dt>
            <span id="state">State</span>
          </dt>
          <dd>{therapistEntity.state}</dd>
          <dt>
            <span id="dateOfBirth">Date Of Birth</span>
          </dt>
          <dd>
            <TextFormat value={therapistEntity.dateOfBirth} type="date" format={APP_LOCAL_DATE_FORMAT} />
          </dd>
          <dt>
            <span id="description">Description</span>
          </dt>
          <dd>{therapistEntity.description}</dd>
          <dt>
            <span id="specializations">Specializations</span>
          </dt>
          <dd>{therapistEntity.specializations}</dd>
          <dt>
            <span id="imageS3Key">Image S 3 Key</span>
          </dt>
          <dd>{therapistEntity.imageS3Key}</dd>
          <dt>
            <span id="status">Status</span>
          </dt>
          <dd>{therapistEntity.status}</dd>
          <dt>
            <span id="activationKey">Activation Key</span>
          </dt>
          <dd>{therapistEntity.activationKey}</dd>
          <dt>
            <span id="jobRole">Job Role</span>
          </dt>
          <dd>{therapistEntity.jobRole}</dd>
          <dt>
            <span id="rating">Rating</span>
          </dt>
          <dd>{therapistEntity.rating}</dd>
          <dt>
            <span id="phone">Phone</span>
          </dt>
          <dd>{therapistEntity.phone}</dd>

          <dt>
            <span id="accountName">Account Name</span>
          </dt>
          <dd>{therapistEntity.accountName}</dd>
          <dt>
            <span id="accountNumber">Account Number</span>
          </dt>
          <dd>{therapistEntity.accountNumber}</dd>
          <dt>
            <span id="sortCode">Sort Code</span>
          </dt>
          <dd>{therapistEntity.sortCode}</dd>
        </dl>
        <Button tag={Link} to="/therapist" replace color="info">
          <FontAwesomeIcon icon="arrow-left" /> <span className="d-none d-md-inline">Back</span>
        </Button>
        &nbsp;
        <Button tag={Link} to={`/therapist/${therapistEntity.id}/edit`} replace color="primary">
          <FontAwesomeIcon icon="pencil-alt" /> <span className="d-none d-md-inline">Edit</span>
        </Button>
      </Col>
    </Row>
  );
};

const mapStateToProps = ({ therapist }: IRootState) => ({
  therapistEntity: therapist.entity
});

const mapDispatchToProps = { getEntity };

type StateProps = ReturnType<typeof mapStateToProps>;
type DispatchProps = typeof mapDispatchToProps;

export default connect(mapStateToProps, mapDispatchToProps)(TherapistDetail);
