import React, { useState, useEffect, createRef, useRef } from 'react';
import { connect } from 'react-redux';
import { Link, RouteComponentProps } from 'react-router-dom';
import { Button, Col, Row, Table } from 'reactstrap';
import { ICrudGetAllAction, TextFormat, getSortState, IPaginationBaseState, JhiPagination, JhiItemCount } from 'react-jhipster';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { IRootState } from 'app/shared/reducers';
import { getEntities } from './mood-tracker.reducer';
import { IMoodTracker } from 'app/shared/model/mood-tracker.model';
import { APP_DATE_FORMAT, APP_LOCAL_DATE_FORMAT } from 'app/config/constants';
import { ITEMS_PER_PAGE } from 'app/shared/util/pagination.constants';
import { CSVLink } from "react-csv";
import axios from 'axios';

export interface IMoodTrackerProps extends StateProps, DispatchProps, RouteComponentProps<{ url: string }> {}

export const MoodTracker = (props: IMoodTrackerProps) => {
  const [paginationState, setPaginationState] = useState(getSortState(props.location, ITEMS_PER_PAGE));
  const [csvData, setCsvData] = useState([]);
  const csvLinkRef = useRef<CSVLink>(null);

  const getAllEntities = () => {
    props.getEntities(paginationState.activePage - 1, paginationState.itemsPerPage, `${paginationState.sort},${paginationState.order}`);
  };

  const sortEntities = () => {
    getAllEntities();
    props.history.push(
      `${props.location.pathname}?page=${paginationState.activePage}&sort=${paginationState.sort},${paginationState.order}`
    );
  };

  useEffect(() => {
    sortEntities();
  }, [paginationState.activePage, paginationState.order, paginationState.sort]);

  //const sort = p => () => {
  //  setPaginationState({
  //    ...paginationState,
  //    order: paginationState.order === 'asc' ? 'desc' : 'asc',
  //    sort: p
  //  });
  //};

  const handlePagination = currentPage =>
    setPaginationState({
      ...paginationState,
      activePage: currentPage
    });

  const setDownloadedCsvData = (response) => {
	 setCsvData(response.data);
	 setTimeout(() => {
		if(csvLinkRef && csvLinkRef.current) {
			csvLinkRef.current.link.click();
	 	}
      });
  }

  const exportCSVData = () => {
	axios.get("/api/user-mood/csv-sheet").then((response) => {
			  setDownloadedCsvData(response);
		    });
  }

  const { moodTrackerList, match, loading, totalItems } = props;
  return (
	
    <div>
      <h2 id="mood-tracker-heading">
        Mood Tracker
      </h2>
		<input type="button" value="Export to CSV" onClick={exportCSVData} />
	    <CSVLink
		  headers = {[
						{label: "Timestamp", key: "moodDate"},
						{label: "User Name", key: "userName"},
						{label: "Email", key: "email"},
						{label: "Phone", key: "phone"},
						{label: "Mood", key: "mood"},
						{label: "Sleep", key: "sleep"},
						{label: "Energy", key: "energy"},
						{label: "Motivation", key: "motivation"},
						{label: "Anxious", key: "anxious"}
					]}
		  data={csvData}
 		  filename="mood-tracker-quick-sheets.csv"
          ref={csvLinkRef}
		/>
      <div className="table-responsive">
        {moodTrackerList && moodTrackerList.length > 0 ? (
          <Table responsive>
            <thead>
              <tr>
                <th>
                  Timestamp
                </th>
                <th>
                  Name
                </th>
                <th>
                  Email
                </th>
                <th>
                  Phone
                </th>
                <th>
                  Mood
                </th>
                <th>
                  Sleep
                </th>
				<th>
                  Energy
                </th>
				<th>
                  Motivation
                </th>
				<th>
                  Anxious
                </th>
              </tr>
            </thead>
            <tbody>
              {moodTrackerList.map((mood, i) => (
                <tr key={`entity-${i}`}>
                  <td>
					  <TextFormat type="date" value={mood.moodDate} format={APP_LOCAL_DATE_FORMAT} />
                  </td>
                  <td>{mood.userName}</td>
                  <td>{mood.email}</td>
                  <td>
                    {mood.phone}
                  </td>
                  <td>
                    {mood.mood}
                  </td>
                  <td>
                    {mood.sleep}
                  </td>
				  <td>
                    {mood.energy}
                  </td>
				  <td>
                    {mood.motivation}
                  </td>
				  <td>
                    {mood.anxious}
                  </td>
                </tr>
              ))}
            </tbody>
          </Table>
        ) : (
          !loading && <div className="alert alert-warning">No User Mood Sheet found</div>
        )}
      </div>
      <div className={moodTrackerList && moodTrackerList.length > 0 ? '' : 'd-none'}>
        <Row className="justify-content-center">
          <JhiItemCount page={paginationState.activePage} total={totalItems} itemsPerPage={paginationState.itemsPerPage} />
        </Row>
        <Row className="justify-content-center">
          <JhiPagination
            activePage={paginationState.activePage}
            onSelect={handlePagination}
            maxButtons={5}
            itemsPerPage={paginationState.itemsPerPage}
            totalItems={props.totalItems}
          />
        </Row>
      </div>
    </div>
  );
};

const mapStateToProps = ({ moodTracker }: IRootState) => ({
  moodTrackerList: moodTracker.entities,
  loading: moodTracker.loading,
  totalItems: moodTracker.totalItems
});

const mapDispatchToProps = {
  getEntities
};

type StateProps = ReturnType<typeof mapStateToProps>;
type DispatchProps = typeof mapDispatchToProps;

export default connect(mapStateToProps, mapDispatchToProps)(MoodTracker);
