import React, {useEffect} from 'react';
import {connect} from 'react-redux';
import {Link, RouteComponentProps} from 'react-router-dom';
import {Button, Col, Row} from 'reactstrap';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';

import {IRootState} from 'app/shared/reducers';
import {getEntity} from './price.reducer';

export interface IPriceDetailProps extends StateProps, DispatchProps, RouteComponentProps<{ id: string }> {}

export const PriceDetail = (props: IPriceDetailProps) => {
  useEffect(() => {
    props.getEntity(props.match.params.id);
  }, []);

  const { priceEntity } = props;
  return (
    <Row>
      <Col md="8">
        <h2>
          Price [<b>{priceEntity.id}</b>]
        </h2>
        <dl className="jh-entity-details">
          <dt>
            <span id="pricePer30Min">Price Per 30 Min</span>
          </dt>
          <dd>{priceEntity.pricePer30Min}</dd>
          <dt>
            <span id="commissionPercentagePer30Min">Commission Percentage Per 30 Min</span>
          </dt>
          <dd>{priceEntity.commissionPercentagePer30Min}</dd>
          <dt>
            <span id="pricePer50Min">Price Per 50 Min</span>
          </dt>
          <dd>{priceEntity.pricePer50Min}</dd>
          <dt>
            <span id="commissionPercentagePer50Min">Commission Percentage Per 50 Min</span>
          </dt>
          <dd>{priceEntity.commissionPercentagePer50Min}</dd>
        </dl>
        <Button tag={Link} to="/price" replace color="info">
          <FontAwesomeIcon icon="arrow-left" /> <span className="d-none d-md-inline">Back</span>
        </Button>
        &nbsp;
        <Button tag={Link} to={`/price/${priceEntity.id}/edit`} replace color="primary">
          <FontAwesomeIcon icon="pencil-alt" /> <span className="d-none d-md-inline">Edit</span>
        </Button>
      </Col>
    </Row>
  );
};

const mapStateToProps = ({ price }: IRootState) => ({
  priceEntity: price.entity
});

const mapDispatchToProps = { getEntity };

type StateProps = ReturnType<typeof mapStateToProps>;
type DispatchProps = typeof mapDispatchToProps;

export default connect(mapStateToProps, mapDispatchToProps)(PriceDetail);
