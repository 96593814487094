import React, { useEffect } from 'react';
import './activity.scss';

const Activity = () => {
  useEffect(() => {
    const timer = setTimeout(() => {
      const userAgent = navigator.userAgent || navigator.vendor || window.opera;

      if (/android/i.test(userAgent)) {
        window.location.href = 'https://play.google.com/store/apps/details?id=uk.marpewellbeing';
      } else if (/iPad|iPhone|iPod/.test(userAgent) && !window.MSStream) {
        window.location.href = 'https://apps.apple.com/us/app/marpe-wellbeing';
      }
    }, 5000); // 3 seconds

    return () => clearTimeout(timer); // Cleanup the timer on unmount
  }, []);
  return (
      <div className="container">
        <div className="logo-div">
          <img className="logo" src="content/images/marpe-logo.jpeg" />
        </div>
        <div className="text">Marpe Wellbeing is available for both Android and iOS devices</div>
        <div className="buttons">
            <img className="button" onClick={() => window.open('https://play.google.com/store/apps/details?id=uk.marpewellbeing', '_blank')} src="content/images/play-store-button.png" alt="Play Store" />
           <img className="button" onClick={() => window.open('https://apps.apple.com/us/app/marpe-wellbeing', '_blank')} src="content/images/app-store-button.png" alt="App Store" />
        </div>
      </div>
    );
};

export default Activity;
