import React from 'react';
import {Switch} from 'react-router-dom';

import ErrorBoundaryRoute from 'app/shared/error/error-boundary-route';

import PromoCode from './promo-code';
import PromoCodeDetail from './promo-code-detail';
import PromoCodeUpdate from './promo-code-update';
import PromoCodeDeleteDialog from './promo-code-delete-dialog';

const Routes = ({match}) => (
  <>
    <Switch>
      <ErrorBoundaryRoute exact path={`${match.url}/new`} component={PromoCodeUpdate}/>
      <ErrorBoundaryRoute exact path={`${match.url}/:id/edit`} component={PromoCodeUpdate}/>
      <ErrorBoundaryRoute exact path={`${match.url}/:id`} component={PromoCodeDetail}/>
      <ErrorBoundaryRoute path={match.url} component={PromoCode}/>
    </Switch>
    <ErrorBoundaryRoute exact path={`${match.url}/:id/delete`} component={PromoCodeDeleteDialog}/>
  </>
);

export default Routes;
