import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

interface IBlogVideoUploadProps {
  /*imgData: string;*/
  onVideoChange: (e: any) => void;
  onVideoDelete: () => void;
  processing: boolean;
}

const BlogVideoUpload = (props: IBlogVideoUploadProps) => {
  const { /* imgData, */ onVideoChange, onVideoDelete, processing } = props;
  return (
    <div>
      <input id="upload-video" type="file" accept="video/*" onChange={onVideoChange} />
	  {processing ? ( 
		<div className="blog-image-preview">
			Processing...
		</div>
		) : ''
	  }
      {/* imgData && (
        <div className="blog-image-preview">
          {processing ? (
            'Processing...'
          ) : (
            <>
              <img className="blog-image-preview-image" src={imgData} />
              <button className="blog-image-preview-close" onClick={onImageDelete}>
                <FontAwesomeIcon icon="times" />
              </button>
            </>
          )}
        </div>
      ) */}
    </div>
  );
};

export default BlogVideoUpload;
