import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

interface IBlogImageUploadProps {
  imgData: string;
  onImageChange: (e: any) => void;
  onImageDelete: () => void;
  processing: boolean;
}

const BlogImageUpload = (props: IBlogImageUploadProps) => {
  const { imgData, onImageChange, onImageDelete, processing } = props;
  return (
    <div>
      <input id="upload-img" type="file" accept=".jpeg,.jpg,.bmp,.png" onChange={onImageChange} />
      {imgData && (
        <div className="blog-image-preview">
          {processing ? (
            'Processing...'
          ) : (
            <>
              <img className="blog-image-preview-image" src={imgData} />
              <button className="blog-image-preview-close" onClick={onImageDelete}>
                <FontAwesomeIcon icon="times" />
              </button>
            </>
          )}
        </div>
      )}
    </div>
  );
};

export default BlogImageUpload;
