import React from 'react';
import {Switch} from 'react-router-dom';

import ErrorBoundaryRoute from 'app/shared/error/error-boundary-route';

import InterestExpression from './interest-expression';
import InterestExpressionDetail from './interest-expression-detail';
import InterestExpressionDeleteDialog from './interest-expression-delete-dialog';

const Routes = ({ match }) => (
  <>
    <Switch>
      <ErrorBoundaryRoute exact path={`${match.url}/:id/delete`} component={InterestExpressionDeleteDialog} />
      <ErrorBoundaryRoute exact path={`${match.url}/:id`} component={InterestExpressionDetail} />
      <ErrorBoundaryRoute path={match.url} component={InterestExpression} />
    </Switch>
  </>
);

export default Routes;
