import React, { Fragment, useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { Link, RouteComponentProps } from 'react-router-dom';
import { Button, Row, Table } from 'reactstrap';
import { getSortState, JhiPagination, JhiItemCount } from 'react-jhipster';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { IRootState } from 'app/shared/reducers';
import { getEntities } from 'app/entities/therapist/therapist.reducer';
import { ITEMS_PER_PAGE } from 'app/shared/util/pagination.constants';

import DocumentsTable from '../therapist-verification-document';
import { approveTherapistAccount } from '../approval.reducer';

export interface ITherapistProps extends StateProps, DispatchProps, RouteComponentProps<{ url: string }> {}

export const Therapist = (props: ITherapistProps) => {
  const [viewedTherapistId, setViewedTherapistId] = useState(null);
  const [paginationState, setPaginationState] = useState(getSortState(props.location, ITEMS_PER_PAGE));

  const approveTherapist = (therapistId: number) => props.approveTherapistAccount(therapistId);

  const getAllEntities = () => {
    props.getEntities(paginationState.activePage - 1, paginationState.itemsPerPage, `${paginationState.sort},${paginationState.order}`);
  };

  const sortEntities = () => {
    getAllEntities();
    props.history.push(
      `${props.location.pathname}?page=${paginationState.activePage}&sort=${paginationState.sort},${paginationState.order}`
    );
  };

  const updateViewedTherapistId = (id: number) => {
    if (id === viewedTherapistId) {
      setViewedTherapistId(null);
      return;
    }
    setViewedTherapistId(id);
  };

  useEffect(() => {
    sortEntities();
  }, [paginationState.activePage, paginationState.order, paginationState.sort]);

  const sort = (p: string) => () => {
    setPaginationState({
      ...paginationState,
      order: paginationState.order === 'asc' ? 'desc' : 'asc',
      sort: p
    });
  };

  const handlePagination = (currentPage: number) =>
    setPaginationState({
      ...paginationState,
      activePage: currentPage
    });

  const { therapistList, loading, totalItems } = props;

  return (
    <div>
      <div className="table-responsive">
        {therapistList && therapistList.length > 0 ? (
          <Table responsive>
            <thead>
              <tr>
                <th className="hand" onClick={sort('id')}>
                  ID <FontAwesomeIcon icon="sort" />
                </th>
                <th className="hand" onClick={sort('firstName')}>
                  First Name <FontAwesomeIcon icon="sort" />
                </th>
                <th className="hand" onClick={sort('lastName')}>
                  Last Name <FontAwesomeIcon icon="sort" />
                </th>
                <th className="hand" onClick={sort('email')}>
                  Email <FontAwesomeIcon icon="sort" />
                </th>
                <th className="hand" onClick={sort('description')}>
                  Description <FontAwesomeIcon icon="sort" />
                </th>
                <th className="hand" onClick={sort('specializations')}>
                  Specializations <FontAwesomeIcon icon="sort" />
                </th>
                <th className="hand">Status</th>
                <th className="hand" onClick={sort('specializations')}>
                  Actions
                </th>
              </tr>
            </thead>
            <tbody>
              {therapistList.map((therapist, i) => (
                <Fragment key={`entity-${i}`}>
                  <tr>
                    <td>{therapist.id}</td>
                    <td>{therapist.firstName}</td>
                    <td>{therapist.lastName}</td>
                    <td>{therapist.email}</td>
                    <td>{therapist.description}</td>
                    <td>{therapist.specializations}</td>
                    <td>{therapist.status}</td>
                    <td className="text-right">
                      <div className="btn-group flex-btn-group-container">
                        <Button color="info" size="sm" onClick={() => updateViewedTherapistId(therapist.id)}>
                          <FontAwesomeIcon icon={therapist.id === viewedTherapistId ? 'eye-slash' : 'eye'} />
                          <span className="d-none d-md-inline">
                            {therapist.id === viewedTherapistId ? 'Hide documents' : 'Show documents'}
                          </span>
                        </Button>
                        &nbsp;
                        {therapist.status !== 'APPROVED' && (
                          <Button color="primary" size="sm" onClick={() => approveTherapist(therapist.id)}>
                            <FontAwesomeIcon icon="check-circle" />
                            <span className="d-none d-md-inline">Approve</span>
                          </Button>
                        )}
                      </div>
                    </td>
                  </tr>
                  {therapist.id === viewedTherapistId && (
                    <tr>
                      <td colSpan={8}>{<DocumentsTable therapistId={viewedTherapistId} />}</td>
                    </tr>
                  )}
                </Fragment>
              ))}
            </tbody>
          </Table>
        ) : (
          !loading && <div className="alert alert-warning">No Therapists found</div>
        )}
      </div>
      <div className={therapistList && therapistList.length > 0 ? '' : 'd-none'}>
        <Row className="justify-content-center">
          <JhiItemCount page={paginationState.activePage} total={totalItems} itemsPerPage={paginationState.itemsPerPage} />
        </Row>
        <Row className="justify-content-center">
          <JhiPagination
            activePage={paginationState.activePage}
            onSelect={handlePagination}
            maxButtons={5}
            itemsPerPage={paginationState.itemsPerPage}
            totalItems={props.totalItems}
          />
        </Row>
      </div>
    </div>
  );
};

const mapStateToProps = ({ therapist }: IRootState) => ({
  therapistList: therapist.entities,
  loading: therapist.loading,
  totalItems: therapist.totalItems
});

const mapDispatchToProps = {
  approveTherapistAccount,
  getEntities
};

type StateProps = ReturnType<typeof mapStateToProps>;
type DispatchProps = typeof mapDispatchToProps;

export default connect(mapStateToProps, mapDispatchToProps)(Therapist);
