import { IRootState } from 'app/shared/reducers';
import {
  createEntity,
  getEntity, getPostCodes, getStripeKey,
  reset,
  updateEntity
} from 'app/entities/therapist/therapist.reducer';
import { connect } from 'react-redux';
import { RouteComponentProps } from 'react-router-dom';
import TherapistUpdate from 'app/entities/therapist/therapist-update';
import React, { useEffect } from 'react';
import { loadStripe } from '@stripe/stripe-js/pure';
import { Elements } from '@stripe/react-stripe-js';

export interface ITherapistUpdateProps extends StateProps, DispatchProps, RouteComponentProps<{ id: string }> {}

const TherapistUpdateContainer = props => {
  useEffect(() => {
    props.getStripeKey();
  }, []);
  const stripePromise = props.stripeKey && loadStripe(props.stripeKey);
    return (
      <Elements stripe={stripePromise}>
        <TherapistUpdate {...props} />
      </Elements>
    );
};

const mapStateToProps = (storeState: IRootState) => ({
  therapistEntity: storeState.therapist.entity,
  loading: storeState.therapist.loading,
  updating: storeState.therapist.updating,
  updateSuccess: storeState.therapist.updateSuccess,
  stripeKey: storeState.therapist.stripeKey,
  postCodes: storeState.therapist.postCodes,

});

const mapDispatchToProps = {
  getEntity,
  updateEntity,
  createEntity,
  reset,
  getStripeKey,
  getPostCodes,
};

type StateProps = ReturnType<typeof mapStateToProps>;
type DispatchProps = typeof mapDispatchToProps;

export default connect(mapStateToProps, mapDispatchToProps)(TherapistUpdateContainer);
