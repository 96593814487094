import React from 'react';
import MenuItem from 'app/shared/layout/menus/menu-item';
import { NavDropdown } from './menu-components';

export const EntitiesMenu = props => (
  <NavDropdown icon="th-list" name="Entities" id="entity-menu" style={{ maxHeight: '80vh', overflow: 'auto' }}>
    <MenuItem icon="asterisk" to="/client">
      Client
    </MenuItem>
    <MenuItem icon="asterisk" to="/therapist">
      Therapist
    </MenuItem>
    <MenuItem icon="asterisk" to="/appointment">
      Appointment
    </MenuItem>
    <MenuItem icon="asterisk" to="/verification-document">
      Verification Document
    </MenuItem>
    <MenuItem icon="asterisk" to="/price">
      Price
    </MenuItem>
    <MenuItem icon="asterisk" to="/blog-post">
      Blog Post
    </MenuItem>
    <MenuItem icon="asterisk" to="/promo-code">
      Promo Code
    </MenuItem>
    <MenuItem icon="asterisk" to="/interest-expression">
      Interest Expression
    </MenuItem>
    <MenuItem icon="asterisk" to="/mood-tips">
      Mood Tips
    </MenuItem>
    <MenuItem icon="asterisk" to="/mood-tracker">
      Mood Tracker
    </MenuItem>
    <MenuItem icon="asterisk" to="/diagnostic">
      Diagnostic
    </MenuItem>
    <MenuItem icon="asterisk" to="/notifications">
      Notifications
    </MenuItem>
    {/* jhipster-needle-add-entity-to-menu - JHipster will add entities to the menu here */}
  </NavDropdown>
);
