import axios from 'axios';
import { ICrudGetAction, ICrudGetAllAction, ICrudPutAction, ICrudDeleteAction } from 'react-jhipster';

import { cleanEntity } from 'app/shared/util/entity-utils';
import { REQUEST, SUCCESS, FAILURE } from 'app/shared/reducers/action-type.util';

import { ITherapist, defaultValue } from 'app/shared/model/therapist.model';

export const ACTION_TYPES = {
  FETCH_THERAPIST_LIST: 'therapist/FETCH_THERAPIST_LIST',
  FETCH_THERAPIST: 'therapist/FETCH_THERAPIST',
  CREATE_THERAPIST: 'therapist/CREATE_THERAPIST',
  UPDATE_THERAPIST: 'therapist/UPDATE_THERAPIST',
  DELETE_THERAPIST: 'therapist/DELETE_THERAPIST',
  GET_STRIPE_KEY: 'therapist/GET_STRIPE_KEY',
  RESET: 'therapist/RESET',
  GET_POSTCODES: 'GET_POSTCODES'
};

const initialState = {
  loading: false,
  errorMessage: null,
  entities: [] as ReadonlyArray<ITherapist>,
  entity: defaultValue,
  updating: false,
  totalItems: 0,
  updateSuccess: false,
  stripeKey: null,
  stripeToken: null,
  postCodes: []
};

export type TherapistState = Readonly<typeof initialState>;

// Reducer

export default (state: TherapistState = initialState, action): TherapistState => {
  switch (action.type) {
    case REQUEST(ACTION_TYPES.FETCH_THERAPIST_LIST):
    case REQUEST(ACTION_TYPES.FETCH_THERAPIST):
      return {
        ...state,
        errorMessage: null,
        updateSuccess: false,
        loading: true
      };
    case REQUEST(ACTION_TYPES.CREATE_THERAPIST):
    case REQUEST(ACTION_TYPES.UPDATE_THERAPIST):
    case REQUEST(ACTION_TYPES.GET_POSTCODES):
    case REQUEST(ACTION_TYPES.GET_STRIPE_KEY):
    case REQUEST(ACTION_TYPES.DELETE_THERAPIST):
      return {
        ...state,
        errorMessage: null,
        updateSuccess: false,
        updating: true
      };
    case FAILURE(ACTION_TYPES.FETCH_THERAPIST_LIST):
    case FAILURE(ACTION_TYPES.FETCH_THERAPIST):
    case FAILURE(ACTION_TYPES.CREATE_THERAPIST):
    case FAILURE(ACTION_TYPES.UPDATE_THERAPIST):
    case FAILURE(ACTION_TYPES.DELETE_THERAPIST):
    case FAILURE(ACTION_TYPES.GET_POSTCODES):
    case FAILURE(ACTION_TYPES.GET_STRIPE_KEY):
      return {
        ...state,
        loading: false,
        updating: false,
        updateSuccess: false,
        errorMessage: action.payload
      };
    case SUCCESS(ACTION_TYPES.FETCH_THERAPIST_LIST):
      return {
        ...state,
        loading: false,
        entities: action.payload.data,
        totalItems: parseInt(action.payload.headers['x-total-count'], 10)
      };
    case SUCCESS(ACTION_TYPES.FETCH_THERAPIST):
      return {
        ...state,
        loading: false,
        entity: action.payload.data
      };
    case SUCCESS(ACTION_TYPES.CREATE_THERAPIST):
    case SUCCESS(ACTION_TYPES.UPDATE_THERAPIST):
      return {
        ...state,
        updating: false,
        updateSuccess: true,
        entity: action.payload.data
      };
    case SUCCESS(ACTION_TYPES.DELETE_THERAPIST):
      return {
        ...state,
        updating: false,
        updateSuccess: true,
        entity: {}
      };
    case SUCCESS(ACTION_TYPES.GET_STRIPE_KEY):
      return {
        ...state,
        errorMessage: null,
        updateSuccess: true,
        loading: false,
        stripeKey: action.payload.data.key
      };
    case SUCCESS(ACTION_TYPES.GET_POSTCODES):
      return {
        ...state,
        errorMessage: null,
        updateSuccess: true,
        loading: false,
        postCodes: action.payload
      };
    case ACTION_TYPES.RESET:
      return {
        ...initialState
      };
    default:
      return state;
  }
};

const apiUrl = 'api/therapists';
const stripePublicTokenUrl = 'api/payment/stripe-public-key';
const getPostCodesUrl = postCode => `https://api.postcodes.io/postcodes/${postCode}/autocomplete`;

// Actions

export const getEntities: ICrudGetAllAction<ITherapist> = (page, size, sort) => {
  const requestUrl = `${apiUrl}${sort ? `?page=${page}&size=${size}&sort=${sort}` : ''}`;
  return {
    type: ACTION_TYPES.FETCH_THERAPIST_LIST,
    payload: axios.get<ITherapist>(`${requestUrl}${sort ? '&' : '?'}cacheBuster=${new Date().getTime()}`)
  };
};

export const getEntity: ICrudGetAction<ITherapist> = id => {
  const requestUrl = `${apiUrl}/${id}`;
  return {
    type: ACTION_TYPES.FETCH_THERAPIST,
    payload: axios.get<ITherapist>(requestUrl)
  };
};

export const getPostCodes: ICrudGetAction<ITherapist> = id => {
  return {
    type: ACTION_TYPES.GET_POSTCODES,
    payload: fetch(getPostCodesUrl(id))
      .then(res => res.json())
      .then(({ result }) => result || [])
      .catch(err => console.error(err))
  };
};

export const getStripeKey: ICrudGetAction<ITherapist> = () => {
  return {
    type: ACTION_TYPES.GET_STRIPE_KEY,
    payload: axios.get(stripePublicTokenUrl)
  };
};

export const createEntity: ICrudPutAction<ITherapist> = entity => async dispatch => {
  const result = await dispatch({
    type: ACTION_TYPES.CREATE_THERAPIST,
    payload: axios.post(apiUrl, cleanEntity(entity))
  });
  dispatch(getEntities());
  return result;
};

export const updateEntity: ICrudPutAction<ITherapist> = entity => async dispatch => {
  const result = await dispatch({
    type: ACTION_TYPES.UPDATE_THERAPIST,
    payload: axios.put(apiUrl, cleanEntity(entity))
  });
  return result;
};

export const deleteEntity: ICrudDeleteAction<ITherapist> = id => async dispatch => {
  const requestUrl = `${apiUrl}/${id}`;
  const result = await dispatch({
    type: ACTION_TYPES.DELETE_THERAPIST,
    payload: axios.delete(requestUrl)
  });
  return result;
};

export const reset = () => ({
  type: ACTION_TYPES.RESET
});
