import { Moment } from 'moment';
import { DocumentType } from 'app/shared/model/enumerations/document-type.model';

export interface IVerificationDocument {
  id?: number;
  title?: string;
  s3Key?: string;
  url?: string;
  validityFrom?: Moment;
  validityTo?: Moment;
  isValid?: boolean;
  isCurrent?: boolean;
  isExpiresInAFewDays?: boolean;
  documentType?: DocumentType;
  therapistId?: number;
  owner?: string;
}

export interface IVerificationDocumentValidityDates {
  id?: number;
  therapistId?: number;
  validityFrom?: string;
  validityTo?: string;
}

export const defaultValue: Readonly<IVerificationDocument> = {
  isValid: false,
  isCurrent: false,
  isExpiresInAFewDays: false
};
