import React, {useEffect} from 'react';
import {connect} from 'react-redux';
import {Link, RouteComponentProps} from 'react-router-dom';
import {Button, Table} from 'reactstrap';
import {TextFormat} from 'react-jhipster';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';

import {IRootState} from 'app/shared/reducers';
import {getEntities} from './verification-document.reducer';
import {APP_LOCAL_DATE_FORMAT} from 'app/config/constants';

export interface IVerificationDocumentProps extends StateProps, DispatchProps, RouteComponentProps<{ url: string }> {
}

export const VerificationDocument = (props: IVerificationDocumentProps) => {
  useEffect(() => {
    props.getEntities();
  }, []);

  const {verificationDocumentList, match, loading} = props;
  return (
    <div>
      <h2 id="verification-document-heading">
        Verification Documents
        <Link style={{display: 'none'}} to={`${match.url}/new`} className="btn btn-primary float-right jh-create-entity" id="jh-create-entity">
          <FontAwesomeIcon icon="plus"/>
          &nbsp; Create new Verification Document
        </Link>
      </h2>
      <div className="table-responsive">
        {verificationDocumentList && verificationDocumentList.length > 0 ? (
          <Table responsive>
            <thead>
            <tr>
              <th>ID</th>
              <th>Therapist Name</th>
              <th>Title</th>
              <th>S 3 Key</th>
              <th>Validity From</th>
              <th>Validity To</th>
              <th>Is Valid</th>
              <th>Is Current</th>
              <th>Is Expires In A Few Days</th>
              <th>Type</th>
              <th>Therapist</th>
              <th/>
            </tr>
            </thead>
            <tbody>
            {verificationDocumentList.map((verificationDocument, i) => (
              <tr key={`entity-${i}`}>
                <td>
                  <Button tag={Link} to={`${match.url}/${verificationDocument.id}`} color="link" size="sm">
                    {verificationDocument.id}
                  </Button>
                </td>
                <td>{verificationDocument.owner}</td>
                <td>{verificationDocument.title}</td>
                <td>{verificationDocument.s3Key}</td>
                <td>
                  {verificationDocument.validityFrom ? (
                    <TextFormat type="date" value={verificationDocument.validityFrom} format={APP_LOCAL_DATE_FORMAT}/>
                  ) : null}
                </td>
                <td>
                  {verificationDocument.validityTo ? (
                    <TextFormat type="date" value={verificationDocument.validityTo} format={APP_LOCAL_DATE_FORMAT}/>
                  ) : null}
                </td>
                <td>{verificationDocument.isValid ? 'true' : 'false'}</td>
                <td>{verificationDocument.isCurrent ? 'true' : 'false'}</td>
                <td>{verificationDocument.isExpiresInAFewDays ? 'true' : 'false'}</td>
                <td>{verificationDocument.documentType}</td>
                <td>
                  {verificationDocument.therapistId ? (
                    <Link to={`therapist/${verificationDocument.therapistId}`}>{verificationDocument.therapistId}</Link>
                  ) : (
                    ''
                  )}
                </td>
                <td className="text-right">
                  <div className="btn-group flex-btn-group-container">
                    <Button tag={Link} to={`${match.url}/${verificationDocument.id}`} color="info" size="sm">
                      <FontAwesomeIcon icon="eye"/> <span className="d-none d-md-inline">View</span>
                    </Button>
                    <Button tag={Link} to={`${match.url}/${verificationDocument.id}/edit`} color="primary" size="sm">
                      <FontAwesomeIcon icon="pencil-alt"/> <span className="d-none d-md-inline">Edit</span>
                    </Button>
                    <Button tag={Link} to={`${match.url}/${verificationDocument.id}/delete`} color="danger" size="sm">
                      <FontAwesomeIcon icon="trash"/> <span className="d-none d-md-inline">Delete</span>
                    </Button>
                  </div>
                </td>
              </tr>
            ))}
            </tbody>
          </Table>
        ) : (
          !loading && <div className="alert alert-warning">No Verification Documents found</div>
        )}
      </div>
    </div>
  );
};

const mapStateToProps = ({verificationDocument}: IRootState) => ({
  verificationDocumentList: verificationDocument.entities,
  loading: verificationDocument.loading,
});

const mapDispatchToProps = {
  getEntities,
};

type StateProps = ReturnType<typeof mapStateToProps>;
type DispatchProps = typeof mapDispatchToProps;

export default connect(mapStateToProps, mapDispatchToProps)(VerificationDocument);
