import React, {useEffect, useState} from 'react';
import axios from 'axios';
import {connect} from 'react-redux';
import {Link, RouteComponentProps} from 'react-router-dom';
import {Button, Col, Label, Row} from 'reactstrap';
import {AvField, AvForm, AvGroup, AvInput} from 'availity-reactstrap-validation';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {IRootState} from 'app/shared/reducers';

import BlogImageUpload from './blog-image-upload';
import BlogVideoUpload from './blog-video-upload';
import {createEntity, getEntity, reset, updateEntity} from './blog-post.reducer';
import {convertDateTimeFromServer, convertDateTimeToServer, displayDefaultDateTime} from 'app/shared/util/date-utils';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';

export interface IBlogPostUpdateProps extends StateProps, DispatchProps, RouteComponentProps<{ id: string }> {}

export const BlogPostUpdate = (props: IBlogPostUpdateProps) => {
  const [isNew, setIsNew] = useState(!props.match.params || !props.match.params.id);
  const {blogPostEntity, loading, updating} = props;

  const [image, setImage] = useState(null);
  const [video, setVideo] = useState(null);
  const [imgData, setImgData] = useState(null);
  const [imgUploading, setImgUploading] = useState(false);
  const [videoUploading, setVideoUploading] = useState(false);
  const [showSelectImage, setShowSelectImage] = useState(true);
  const [showSelectVideo, setShowSelectVideo] = useState(true);
  const [showBlogPostText, setShowBlogPostText] = useState(true);
  const [showUrl, setShowUrl] = useState(false);
  const [textAreaValue, setTextAreaValue] = useState('');
  const toolbarOptions = [
						  ['bold', 'italic', 'underline', 'strike'],        // toggled buttons
						  ['blockquote', 'code-block'],
						
						  [{ 'header': 1 }, { 'header': 2 }],               // custom button values
						  [{ 'list': 'ordered'}, { 'list': 'bullet' }],
						  [{ 'script': 'sub'}, { 'script': 'super' }],      // superscript/subscript
						  [{ 'indent': '-1'}, { 'indent': '+1' }],          // outdent/indent
						  [{ 'direction': 'rtl' }],                         // text direction
						
						  [{ 'size': ['small', false, 'large', 'huge'] }],  // custom dropdown
						  [{ 'header': [1, 2, 3, 4, 5, 6, false] }],
						
						  [{ 'color': [] }, { 'background': [] }],          // dropdown with defaults from theme
						  [{ 'font': [] }],
						  [{ 'align': [] }],
						
						  ['clean']                                         // remove formatting button
						];
  

  const handleClose = () => {
    props.history.push('/blog-post' + props.location.search);
  };

  const onImageChange = e => {
    if (e.target.files[0]) {
      setImage(e.target.files[0]);
      const reader = new FileReader();
      reader.addEventListener("load", () => {
        setImgData(reader.result);
      });
      reader.readAsDataURL(e.target.files[0]);
    }
  };

  const onImageUpload = async () => {
    const formData = new FormData();
    formData.append('file', image);
    const requestUrl = 'api/blog-posts/image';
    setImgUploading(true);
    const payload = await axios.post(requestUrl, formData);
    setImgUploading(false);
    return payload.data.url;
  }

  const onImageDelete = () => {
    setImage(null);
    setImgData(null);
  };
	
  const onVideoChange = (e) => {
	if (e.target.files[0]) {
      setVideo(e.target.files[0]);
    }
  }

  const onVideoUpload = async () => {
  	const formData = new FormData();
    formData.append('file', video);
    const requestUrl = 'api/blog-posts/video';
    setVideoUploading(true);
    const payload = await axios.post(requestUrl, formData, {timeout: 900000});
    setVideoUploading(false);
    return payload.data.url;
  }

  const onVideoDelete = () => {
	
  }

  const onBlogTypeChange = (e) => {
		const selectValue = e.target.value;
		if(selectValue === "Resource Center") {
			setShowSelectVideo(false);
			setShowBlogPostText(true);
			setShowUrl(false);
		} else if (selectValue === "Videos") {
			setShowSelectImage(true);
			setShowSelectVideo(true);
			setShowBlogPostText(true);
			setShowUrl(false);
		} else if (selectValue === "Links") {
			setShowSelectVideo(false);
			setShowBlogPostText(false);
			setShowSelectImage(true);
			setShowUrl(true);
		}
	}

  useEffect(() => {
    if (isNew) {
      props.reset();
    } else {
      props.getEntity(props.match.params.id);
    }
  }, []);

  useEffect(() => {
    if (props.updateSuccess) {
      handleClose();
    }
  }, [props.updateSuccess]);

  const saveEntity = async (event, errors, values) => {
    values.createdDate = convertDateTimeToServer(values.createdDate);
    if (image) {
      const imageUrl = await onImageUpload();
      values.image = imageUrl;
    }

    if(!image) {
      values.image = "";
    }

	if(video) {
	  const videoUrl = await onVideoUpload();
      values.video = videoUrl;
	}
	
	if(!video) {
		values.video = "";
	}
	
    if(textAreaValue !== '') {
		values.blogText = textAreaValue;	
	} else {
		values.blogText = blogPostEntity.blogText || '';
	}
    
    if (errors.length === 0) {
      const entity = {
        ...blogPostEntity,
        ...values,
      };

      if (isNew) {
		props.createEntity(entity);
      } else {
        props.updateEntity(entity);
      }
    }
  };

  return (
    <div>
      <Row className="justify-content-center">
        <Col md="8">
          <h2 id="marpeWellbeingApp.blogPost.home.createOrEditLabel">Create or edit a BlogPost</h2>
        </Col>
      </Row>
      <Row className="justify-content-center">
        <Col md="8">
          {loading ? (
            <p>Loading...</p>
          ) : (
            <AvForm model={isNew ? {} : blogPostEntity} onSubmit={saveEntity}>
              {!isNew ? (
                <AvGroup>
                  <Label for="blog-post-id">ID</Label>
                  <AvInput id="blog-post-id" type="text" className="form-control" name="id" required readOnly/>
                </AvGroup>
              ) : null}
              <AvGroup>
                <Label id="titleLabel" for="blog-post-title">
                  Title
                </Label>
                <AvField
                  id="blog-post-title"
                  type="text"
                  name="title"
                  validate={{
                    required: {value: true, errorMessage: 'This field is required.'},
                    maxLength: {value: 500, errorMessage: 'This field cannot be longer than 500 characters.'},
                  }}
                />
              </AvGroup>
				
			  <AvGroup>
                <Label for="upload-img">
                  Blog Type
                </Label>
                <AvField type="select" name="blogType" helpMessage="These are blog types" onChange={onBlogTypeChange} validate={{
						required: {value: true, errorMessage: 'This field is required.'}
					}}>
          			<option value="">Select Blog Type</option>
					<option value="Resource Center">Resource Center</option>
          			<option value="Videos">Videos</option>
					<option value="Links">Links</option>
        		</AvField>
              </AvGroup>
				
              {showSelectImage && <AvGroup>
                <Label for="upload-img">
                  Image
                </Label>
                <BlogImageUpload
                  imgData={imgData || blogPostEntity.image}
                  processing={imgUploading}
                  onImageChange={onImageChange}
                  onImageDelete={onImageDelete}
                />
              </AvGroup>
			  }
			  {showSelectVideo && 
			  <AvGroup>
                <Label for="upload-img">
                  Video
                </Label>
                <BlogVideoUpload
                  processing={videoUploading}
                  onVideoChange={onVideoChange}
                  onVideoDelete={onVideoDelete}
                />
              </AvGroup>
			  }
			  {showBlogPostText && <AvGroup>
				<Label id="blogText" for="blog-post-text">
                  Blog Post Text 
                </Label>
				
				<ReactQuill key={blogPostEntity.id} theme="snow" defaultValue={blogPostEntity.blogText} onChange={setTextAreaValue} modules={{toolbar: toolbarOptions}} readOnly={false}/>
				{/* 
				<AvInput 
					type="textarea" 
					name="blogText" 
					id="blog-post-text"
					validate={{
						required: {value: true, errorMessage: 'This field is required.'},
						maxLength: {value: 2048, errorMessage: 'This field cannot be longer than 2048 characters.'}
					}}
					 />
				 */}
			  </AvGroup>
			  }
              {
			  showUrl &&
			  <AvGroup>
                <Label id="urlLabel" for="blog-post-url">
                  Url
                </Label>
                <AvField
                  id="blog-post-url"
                  type="text"
                  name="url"
                  validate={{
                    required: {value: true, errorMessage: 'This field is required.'},
                    maxLength: {value: 2048, errorMessage: 'This field cannot be longer than 2048 characters.'},
                  }}
                />
              </AvGroup>
			  }
              <AvGroup>
                <Label id="createdDateLabel" for="blog-post-createdDate">
                  Created Date
                </Label>
                <AvInput
                  id="blog-post-createdDate"
                  type="datetime-local"
                  className="form-control"
                  name="createdDate"
                  placeholder={'YYYY-MM-DD HH:mm'}
                  value={isNew ? displayDefaultDateTime() : convertDateTimeFromServer(props.blogPostEntity.createdDate)}
                />
              </AvGroup>
              <Button tag={Link} id="cancel-save" to="/blog-post" replace color="info">
                <FontAwesomeIcon icon="arrow-left"/>
                &nbsp;
                <span className="d-none d-md-inline">Back</span>
              </Button>
              &nbsp;
              <Button color="primary" id="save-entity" type="submit" disabled={updating}>
                <FontAwesomeIcon icon="save"/>
                &nbsp; Save
              </Button>
            </AvForm>
          )}
        </Col>
      </Row>
    </div>
  );
};

const mapStateToProps = (storeState: IRootState) => ({
  blogPostEntity: storeState.blogPost.entity,
  loading: storeState.blogPost.loading,
  updating: storeState.blogPost.updating,
  updateSuccess: storeState.blogPost.updateSuccess,
});

const mapDispatchToProps = {
  getEntity,
  updateEntity,
  createEntity,
  reset,
};

type StateProps = ReturnType<typeof mapStateToProps>;
type DispatchProps = typeof mapDispatchToProps;

export default connect(mapStateToProps, mapDispatchToProps)(BlogPostUpdate);
