import axios from 'axios';

import { FAILURE, REQUEST, SUCCESS } from 'app/shared/reducers/action-type.util';
import { getEntities } from 'app/entities/therapist/therapist.reducer';

export const ACTION_TYPES = {
  APPROVE_THERAPIST_ACCOUNT: 'approveTherapist/APPROVE_THERAPIST_ACCOUNT',
  RESET: 'approveTherapist/RESET'
};

const initialState = {
  errorMessage: null,
  updating: false,
  updateSuccess: false
};

export type ApproveTherapistAccountState = Readonly<typeof initialState>;

// Reducer

export default (state: ApproveTherapistAccountState = initialState, action): ApproveTherapistAccountState => {
  switch (action.type) {
    case REQUEST(ACTION_TYPES.APPROVE_THERAPIST_ACCOUNT):
      return {
        ...state,
        errorMessage: null,
        updateSuccess: false,
        updating: true
      };
    case FAILURE(ACTION_TYPES.APPROVE_THERAPIST_ACCOUNT):
      return {
        ...state,
        updating: false,
        updateSuccess: false,
        errorMessage: action.payload
      };
    case SUCCESS(ACTION_TYPES.APPROVE_THERAPIST_ACCOUNT):
      return {
        ...state,
        updating: false
      };
    case ACTION_TYPES.RESET:
      return {
        ...initialState
      };
    default:
      return state;
  }
};

const approveTherapistAccountUrl = (therapistId: number, status = 'APPROVED') => `/api/therapists/${therapistId}/approve?status=${status}`;

// Actions

export const approveTherapistAccount = (id: number) => async dispatch => {
  const result = await dispatch({
    type: ACTION_TYPES.APPROVE_THERAPIST_ACCOUNT,
    payload: axios.patch(approveTherapistAccountUrl(id))
  });
  dispatch(getEntities());
  return result;
};

export const reset = () => ({
  type: ACTION_TYPES.RESET
});
