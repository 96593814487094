import { Moment } from 'moment';
import { AppintmentStatus } from 'app/shared/model/enumerations/appintment-status.model';

export interface IAppointment {
  id?: number;
  status?: AppintmentStatus;
  comment?: string;
  rating?: number;
  startTime?: Moment;
  endTime?: Moment;
  room?: string;
  clientId?: number;
  therapistId?: number;
}

export const defaultValue: Readonly<IAppointment> = {};
