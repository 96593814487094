import * as React from "react";

import ReactChipInput from "react-chip-input";
import { useEffect } from 'react';

const ChipInput = (props) => {
  const { chipsData, setChipsData, ...rest } = props;
  useEffect(() => {
    const input = document.getElementsByName('chipInput')[0].attributes;
    // eslint-disable-next-line @typescript-eslint/ban-ts-ignore
    // @ts-ignore
    input.placeholder.value = 'Push enter to add specialization';
  }, [])
  const addChip = value => {
    const chips = chipsData.slice();
    chips.push(value);
    setChipsData(chips);
  };
  const removeChip = index => {
    const chips = chipsData.slice();
    chips.splice(index, 1);
    setChipsData(chips);
  };
    return (
      <ReactChipInput
        chips={chipsData}
        onSubmit={value => addChip(value)}
        onRemove={index => removeChip(index)}
        {...rest}
      />
    );
}
export default ChipInput;
