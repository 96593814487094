import React, {useEffect} from 'react';
import {connect} from 'react-redux';
import {Link, RouteComponentProps} from 'react-router-dom';
import {Button, Col, Row} from 'reactstrap';
import {TextFormat} from 'react-jhipster';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';

import {IRootState} from 'app/shared/reducers';
import {getEntity} from './verification-document.reducer';
import {APP_LOCAL_DATE_FORMAT} from 'app/config/constants';

export interface IVerificationDocumentDetailProps extends StateProps, DispatchProps, RouteComponentProps<{ id: string }> {
}

export const VerificationDocumentDetail = (props: IVerificationDocumentDetailProps) => {
  useEffect(() => {
    props.getEntity(props.match.params.id);
  }, []);

  const {verificationDocumentEntity} = props;
  return (
    <Row>
      <Col md="8">
        <h2>
          VerificationDocument [<b>{verificationDocumentEntity.id}</b>]
        </h2>
        <dl className="jh-entity-details">
          <dt>
            <span id="owner">Therapist Name</span>
          </dt>
          <dd>{verificationDocumentEntity.owner}</dd>
          <dt>
            <span id="title">Title</span>
          </dt>
          <dd>{verificationDocumentEntity.title}</dd>
          <dt>
            <span id="s3Key">S 3 Key</span>
          </dt>
          <dd>{verificationDocumentEntity.s3Key}</dd>
          <dt>
            <span id="validityFrom">Validity From</span>
          </dt>
          <dd>
            {verificationDocumentEntity.validityFrom ? (
              <TextFormat value={verificationDocumentEntity.validityFrom} type="date" format={APP_LOCAL_DATE_FORMAT}/>
            ) : null}
          </dd>
          <dt>
            <span id="validityTo">Validity To</span>
          </dt>
          <dd>
            {verificationDocumentEntity.validityTo ? (
              <TextFormat value={verificationDocumentEntity.validityTo} type="date" format={APP_LOCAL_DATE_FORMAT}/>
            ) : null}
          </dd>
          <dt>
            <span id="isValid">Is Valid</span>
          </dt>
          <dd>{verificationDocumentEntity.isValid ? 'true' : 'false'}</dd>
          <dt>
            <span id="isCurrent">Is Current</span>
          </dt>
          <dd>{verificationDocumentEntity.isCurrent ? 'true' : 'false'}</dd>
          <dt>
            <span id="isExpiresInAFewDays">Is Expires In A Few Days</span>
          </dt>
          <dd>{verificationDocumentEntity.isExpiresInAFewDays ? 'true' : 'false'}</dd>
          <dt>
            <span id="type">Type</span>
          </dt>
          <dd>{verificationDocumentEntity.documentType}</dd>
          <dt>Therapist</dt>
          <dd>{verificationDocumentEntity.therapistId ? verificationDocumentEntity.therapistId : ''}</dd>
        </dl>
        <Button tag={Link} to="/verification-document" replace color="info">
          <FontAwesomeIcon icon="arrow-left"/> <span className="d-none d-md-inline">Back</span>
        </Button>
        &nbsp;
        <Button tag={Link} to={`/verification-document/${verificationDocumentEntity.id}/edit`} replace color="primary">
          <FontAwesomeIcon icon="pencil-alt"/> <span className="d-none d-md-inline">Edit</span>
        </Button>
      </Col>
    </Row>
  );
};

const mapStateToProps = ({verificationDocument}: IRootState) => ({
  verificationDocumentEntity: verificationDocument.entity,
});

const mapDispatchToProps = {getEntity};

type StateProps = ReturnType<typeof mapStateToProps>;
type DispatchProps = typeof mapDispatchToProps;

export default connect(mapStateToProps, mapDispatchToProps)(VerificationDocumentDetail);
