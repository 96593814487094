import { Moment } from 'moment';

export interface IDiagnostic {
  id?: number;
  title?: string;
  imageUrl?: string;
  summaryText?: string;
  updateTs?: Moment;
  diagnosticQuestions?: [];
  diagnosticScoreRange?: [];
  trendsPage?: string;
}

export const defaultValue: Readonly<IDiagnostic> = {};
