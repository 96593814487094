import { Moment } from 'moment';

export interface INotifications {
  id?: number;
  title?: string;
  description?: string;
  selectedClients?: number[];
  updateTs?: Moment;
}

export const defaultValue: Readonly<INotifications> = {};
