import { Moment } from 'moment';

export interface IInterestExpression {
  id?: number;
  name?: string;
  email?: string;
  date?: Moment;
}

export const defaultValue: Readonly<IInterestExpression> = {};
