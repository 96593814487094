import React, {useEffect, useState} from 'react';
import {connect} from 'react-redux';
import {Link, RouteComponentProps} from 'react-router-dom';
import {Button, Col, Label, Row} from 'reactstrap';
import {AvField, AvForm, AvGroup, AvInput} from 'availity-reactstrap-validation';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {IRootState} from 'app/shared/reducers';
import {getEntities as getTherapists} from 'app/entities/therapist/therapist.reducer';
import {createEntity, getEntity, reset, updateEntity} from './verification-document.reducer';

export interface IVerificationDocumentUpdateProps extends StateProps, DispatchProps, RouteComponentProps<{ id: string }> {
}

export const VerificationDocumentUpdate = (props: IVerificationDocumentUpdateProps) => {
  const [therapistId, setTherapistId] = useState('0');
  const [isNew, setIsNew] = useState(!props.match.params || !props.match.params.id);

  const {verificationDocumentEntity, therapists, loading, updating} = props;

  const handleClose = () => {
    props.history.push('/verification-document');
  };

  useEffect(() => {
    if (isNew) {
      props.reset();
    } else {
      props.getEntity(props.match.params.id);
    }

    props.getTherapists();
  }, []);

  useEffect(() => {
    if (props.updateSuccess) {
      handleClose();
    }
  }, [props.updateSuccess]);

  const saveEntity = (event, errors, values) => {
    if (errors.length === 0) {
      const entity = {
        ...verificationDocumentEntity,
        ...values,
      };

      if (isNew) {
        props.createEntity(entity);
      } else {
        props.updateEntity(entity);
      }
    }
  };

  return (
    <div>
      <Row className="justify-content-center">
        <Col md="8">
          <h2 id="marpeWellbeingApp.verificationDocument.home.createOrEditLabel">Create or edit a
            VerificationDocument</h2>
        </Col>
      </Row>
      <Row className="justify-content-center">
        <Col md="8">
          {loading ? (
            <p>Loading...</p>
          ) : (
            <AvForm model={isNew ? {} : verificationDocumentEntity} onSubmit={saveEntity}>
              {!isNew ? (
                <AvGroup>
                  <Label for="verification-document-id">ID</Label>
                  <AvInput id="verification-document-id" type="text" className="form-control" name="id" required
                           readOnly/>
                </AvGroup>
              ) : null}
              <AvGroup>
                <Label id="titleLabel" for="verification-document-title">
                  Title
                </Label>
                <AvField
                  id="verification-document-title"
                  type="text"
                  name="title"
                  validate={{
                    required: {value: true, errorMessage: 'This field is required.'},
                    maxLength: {value: 1024, errorMessage: 'This field cannot be longer than 1024 characters.'},
                  }}
                />
              </AvGroup>
              <AvGroup>
                <Label id="validityFromLabel" for="verification-document-validityFrom">
                  Validity From
                </Label>
                <AvField
                  id="verification-document-validityFrom"
                  type="date"
                  className="form-control"
                  name="validityFrom"
                />
              </AvGroup>
              <AvGroup>
                <Label id="validityToLabel" for="verification-document-validityTo">
                  Validity To
                </Label>
                <AvField
                  id="verification-document-validityTo"
                  type="date"
                  className="form-control"
                  name="validityTo"
                />
              </AvGroup>
              <AvGroup check>
                <Label id="isValidLabel">
                  <AvInput id="verification-document-isValid" type="checkbox" className="form-check-input"
                           name="isValid"/>
                  Is Valid
                </Label>
              </AvGroup>
              <AvGroup check>
                <Label id="isCurrentLabel">
                  <AvInput id="verification-document-isCurrent" type="checkbox" className="form-check-input"
                           name="isCurrent"/>
                  Is Current
                </Label>
              </AvGroup>
              <AvGroup check>
                <Label id="isExpiresInAFewDaysLabel">
                  <AvInput
                    id="verification-document-isExpiresInAFewDays"
                    type="checkbox"
                    className="form-check-input"
                    name="isExpiresInAFewDays"
                  />
                  Is Expires In A Few Days
                </Label>
              </AvGroup>
              <AvGroup>
                <Label id="typeLabel" for="verification-document-type">
                  Type
                </Label>
                <AvInput
                  id="verification-document-type"
                  type="select"
                  className="form-control"
                  name="documentType"
                  value={(!isNew && verificationDocumentEntity.documentType) || 'CV'}
                >
                  <option value="CV">CV</option>
                  <option value="REFERENCE_1">REFERENCE_1</option>
                  <option value="REFERENCE_2">REFERENCE_2</option>
                  <option
                    value="PROFESSIONAL_INDEMNITY_PROOF_OF_MEMBERSHIP">PROFESSIONAL_INDEMNITY_PROOF_OF_MEMBERSHIP
                  </option>
                  <option value="RIGHT_TO_WORK">RIGHT_TO_WORK</option>
                  <option value="ID">ID</option>
                  <option value="PROOF_OF_ADDRESS">PROOF_OF_ADDRESS</option>
                  <option value="REGULATORY_BODY_MEMBERSHIP">REGULATORY_BODY_MEMBERSHIP</option>
                  <option value="ENHANCED_DBS_CERTIFICATE">ENHANCED_DBS_CERTIFICATE</option>
                  <option value="SIGNED_DBS_CONSENT_FORM">SIGNED_DBS_CONSENT_FORM</option>
                  <option value="SIGNED_AGREEMENT">SIGNED_AGREEMENT</option>
                  <option value="INTERVIEW">INTERVIEW</option>
                  <option value="MARPE_TUTORIAL">MARPE_TUTORIAL</option>
                  <option value="OTHER">OTHER</option>
                </AvInput>
              </AvGroup>
              <Button tag={Link} id="cancel-save" to="/verification-document" replace color="info">
                <FontAwesomeIcon icon="arrow-left"/>
                &nbsp;
                <span className="d-none d-md-inline">Back</span>
              </Button>
              &nbsp;
              <Button color="primary" id="save-entity" type="submit" disabled={updating}>
                <FontAwesomeIcon icon="save"/>
                &nbsp; Save
              </Button>
            </AvForm>
          )}
        </Col>
      </Row>
    </div>
  );
};

const mapStateToProps = (storeState: IRootState) => ({
  therapists: storeState.therapist.entities,
  verificationDocumentEntity: storeState.verificationDocument.entity,
  loading: storeState.verificationDocument.loading,
  updating: storeState.verificationDocument.updating,
  updateSuccess: storeState.verificationDocument.updateSuccess,
});

const mapDispatchToProps = {
  getTherapists,
  getEntity,
  updateEntity,
  createEntity,
  reset,
};

type StateProps = ReturnType<typeof mapStateToProps>;
type DispatchProps = typeof mapDispatchToProps;

export default connect(mapStateToProps, mapDispatchToProps)(VerificationDocumentUpdate);
