import React, { useState, useEffect, useLayoutEffect } from 'react';
import { connect } from 'react-redux';
import axios from 'axios';
import { Link, RouteComponentProps } from 'react-router-dom';
import { Button, Row, Col, Label } from 'reactstrap';
import { AvFeedback, AvForm, AvGroup, AvInput, AvField } from 'availity-reactstrap-validation';
import { ICrudGetAction, ICrudGetAllAction, ICrudPutAction } from 'react-jhipster';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { IRootState } from 'app/shared/reducers';
import DiagnosticImageUpload from './diagnostic-image-upload';

import { getEntity, updateEntity, createEntity, reset } from './diagnostic.reducer';
import { IDiagnostic } from 'app/shared/model/diagnostic.model';
import { convertDateTimeFromServer, convertDateTimeToServer, displayDefaultDateTime } from 'app/shared/util/date-utils';
import { mapIdList } from 'app/shared/util/entity-utils';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import DiagnosticQuestionsUpdate from './diagnostic-questions-update';
import DiagnosticScoreRangeUpdate from './diagnostic-score-range-update';

export interface IDiagnosticUpdateProps extends StateProps, DispatchProps, RouteComponentProps<{ id: string }> {}

export const DiagnosticUpdate = (props: IDiagnosticUpdateProps) => {
  const [isNew, setIsNew] = useState(!props.match.params || !props.match.params.id);

  const { diagnosticEntity, loading, updating } = props;
  const [imgUploading, setImgUploading] = useState(false);
  const [image, setImage] = useState(null);
  const [imgData, setImgData] = useState(null);
  const [showSelectImage, setShowSelectImage] = useState(true);
  const [showSummaryText, setSummaryText] = useState(true);
  const [textAreaValue, setTextAreaValue] = useState('');
  const toolbarOptions = [
						  ['bold', 'italic', 'underline', 'strike'],        // toggled buttons
						  ['blockquote', 'code-block'],
						
						  [{ 'header': 1 }, { 'header': 2 }],               // custom button values
						  [{ 'list': 'ordered'}, { 'list': 'bullet' }],
						  [{ 'script': 'sub'}, { 'script': 'super' }],      // superscript/subscript
						  [{ 'indent': '-1'}, { 'indent': '+1' }],          // outdent/indent
						  [{ 'direction': 'rtl' }],                         // text direction
						
						  [{ 'size': ['small', false, 'large', 'huge'] }],  // custom dropdown
						  [{ 'header': [1, 2, 3, 4, 5, 6, false] }],
						
						  [{ 'color': [] }, { 'background': [] }],          // dropdown with defaults from theme
						  [{ 'font': [] }],
						  [{ 'align': [] }],
						
						  ['clean']                                         // remove formatting button
						];

  const [diagnosticQuestions, setDiagnosticQuestions] = useState([]);
  const [diagnosticQuestionsRefMap, setDiagnosticQuestionsRefMap] = useState({});
  // const [scoreRangeRef, setScoreRangeRef] = useState();
  const scoreRange1Ref = React.useRef<DiagnosticScoreRangeUpdate>();
  const scoreRange2Ref = React.useRef<DiagnosticScoreRangeUpdate>();
  const scoreRange3Ref = React.useRef<DiagnosticScoreRangeUpdate>();
  const scoreRange4Ref = React.useRef<DiagnosticScoreRangeUpdate>();
  const scoreRange5Ref = React.useRef<DiagnosticScoreRangeUpdate>();
	
  const [scoreRangeDetail1, setScoreRangeDetail1] = useState({scoreFrom: 0, scoreTo: 0, scoreText: ""});
  const [scoreRangeDetail2, setScoreRangeDetail2] = useState({scoreFrom: 0, scoreTo: 0, scoreText: ""});
  const [scoreRangeDetail3, setScoreRangeDetail3] = useState({scoreFrom: 0, scoreTo: 0, scoreText: ""});
  const [scoreRangeDetail4, setScoreRangeDetail4] = useState({scoreFrom: 0, scoreTo: 0, scoreText: ""});
  const [scoreRangeDetail5, setScoreRangeDetail5] = useState({scoreFrom: 0, scoreTo: 0, scoreText: ""});

  const handleClose = () => {
    props.history.push('/diagnostic' + props.location.search);
  };

  const onImageChange = e => {
    if (e.target.files[0]) {
      setImage(e.target.files[0]);
      const reader = new FileReader();
      reader.addEventListener("load", () => {
        setImgData(reader.result);
      });
      reader.readAsDataURL(e.target.files[0]);
    }
  };

  const onImageUpload = async () => {
    const formData = new FormData();
    formData.append('file', image);
    const requestUrl = 'api/diagnostic/image';
    setImgUploading(true);
    const payload = await axios.post(requestUrl, formData);
    setImgUploading(false);
    return payload.data.url;
  }

  const onImageDelete = () => {
    setImage(null);
    setImgData(null);
  };

  const onAddQuestionClick = () => {
	
	const questionsTemp = JSON.parse(JSON.stringify(diagnosticQuestions));
	
	const newQuestionId = 'new_'+new Date().getTime();
	
	const question = {
						
						"id": newQuestionId,
						"questionName" : "",
						"diagnosticQuestionOptions" : [
							{
								id: 'new1',
								optionName: "",
								optionScore: 0
							}, 
							{
								id: 'new2',
								optionName: "",
								optionScore: 0
							}, 
							{
								id: 'new3',
								optionName: "",
								optionScore: 0
							}, 
							{
								id: 'new4',
								optionName: "",
								optionScore: 0
							}
						]
					};
	questionsTemp.push(question);
	
	setDiagnosticQuestions(questionsTemp);
	
	const questionRefMap = {... diagnosticQuestionsRefMap};
	questionRefMap[newQuestionId] = React.createRef();
	setDiagnosticQuestionsRefMap(questionRefMap);
  }

  const refreshQuestions = () => {
	if(!isNew) {
		const questionRefMap = {... diagnosticQuestionsRefMap};
		if(diagnosticEntity.diagnosticQuestions !== null && diagnosticEntity.diagnosticQuestions !== undefined) {
			diagnosticEntity.diagnosticQuestions.forEach(question => {
				const questionId = ''+question['id'];
				questionRefMap[questionId] = React.createRef();
			})
			setDiagnosticQuestionsRefMap(questionRefMap);
			setDiagnosticQuestions(diagnosticEntity.diagnosticQuestions);
		}
		
		if(diagnosticEntity.diagnosticScoreRange !== null && diagnosticEntity.diagnosticScoreRange !== undefined
													 	  && diagnosticEntity.diagnosticScoreRange.length > 0) {
			
			let diagnosticScoreRange = [];
			diagnosticScoreRange = diagnosticEntity.diagnosticScoreRange;
			if(diagnosticScoreRange[0] !== null && diagnosticScoreRange[0] !== undefined) {
				setScoreRangeDetail1({scoreFrom: diagnosticScoreRange[0].scoreRangeFrom, scoreTo: diagnosticScoreRange[0].scoreRangeTo, scoreText: diagnosticScoreRange[0].scoreRangeText});
			}
			
			if(diagnosticScoreRange[1] !== null && diagnosticScoreRange[1] !== undefined) {
				setScoreRangeDetail2({scoreFrom: diagnosticScoreRange[1].scoreRangeFrom, scoreTo: diagnosticScoreRange[1].scoreRangeTo, scoreText: diagnosticScoreRange[1].scoreRangeText});
			}
			
			if(diagnosticScoreRange[2] !== null && diagnosticScoreRange[2] !== undefined) {
				setScoreRangeDetail3({scoreFrom: diagnosticScoreRange[2].scoreRangeFrom, scoreTo: diagnosticScoreRange[2].scoreRangeTo, scoreText: diagnosticScoreRange[2].scoreRangeText});
			}
			
			if(diagnosticScoreRange[3] !== null && diagnosticScoreRange[3] !== undefined) {
				setScoreRangeDetail4({scoreFrom: diagnosticScoreRange[3].scoreRangeFrom, scoreTo: diagnosticScoreRange[3].scoreRangeTo, scoreText: diagnosticScoreRange[3].scoreRangeText});
			}
			
			if(diagnosticScoreRange[4] !== null && diagnosticScoreRange[4] !== undefined) {
				setScoreRangeDetail5({scoreFrom: diagnosticScoreRange[4].scoreRangeFrom, scoreTo: diagnosticScoreRange[4].scoreRangeTo, scoreText: diagnosticScoreRange[4].scoreRangeText});
			}
		}
		
	} else {
		onAddQuestionClick();
	}
  }

  useEffect(() => {
    if (isNew) {
      props.reset();
    } else {
      props.getEntity(props.match.params.id);
    }
  }, []);
 
  useEffect(() => {
    if (props.updateSuccess) {
      handleClose();
    }
  }, [props.updateSuccess]);

  useEffect(() => {
	
	refreshQuestions();
  }, [!props.loading]);



  const saveEntity = async (event, errors, values) => {
	
	 if (image) {
      const imageUrl = await onImageUpload();
      values.imageUrl = imageUrl;
    }


    if(!image) {
      values.image = "";
    }
	
    if (errors.length === 0) {
	
	 const questions = [];
	 for (const questionId in diagnosticQuestionsRefMap){
		if( questionId !== null && diagnosticQuestionsRefMap[questionId].current !== null){
			const questionObj = {
				questionName: diagnosticQuestionsRefMap[questionId].current.state.questionName
			};
			const questionIdTemp = ''+questionId;
			if(!questionIdTemp.includes("new")) {
				questionObj["id"] = questionIdTemp;
			}
			
			const options = [];
			diagnosticQuestionsRefMap[questionId].current.state.options.forEach(option => {
				if(option.optionName !== "" && option.optionScore !== "") {
					const optionObj = {
						optionName: option.optionName,
						optionScore: option.optionScore
					}
					const optionId = ''+option.id;
					if(!optionId.includes('new')) {
						optionObj["id"] = optionId;
					}
					options.push(optionObj);
				}
			});
			questionObj["diagnosticQuestionOptions"] = options;
			questions.push(questionObj);
		}
    }
	  
	values.diagnosticQuestions = questions;
    
    const scoreRanges = [];
	
	if(scoreRange1Ref.current !== null && scoreRange1Ref.current.state.scoreRangeFrom !== "" && scoreRange1Ref.current.state.scoreRangeTo !== ""
													 && scoreRange1Ref.current.state.scoreRangeText !== ""){
		scoreRanges.push(scoreRange1Ref.current.state);											
	}
	if(scoreRange2Ref.current !== null && scoreRange2Ref.current.state.scoreRangeFrom !== "" && scoreRange2Ref.current.state.scoreRangeTo !== ""
													 && scoreRange2Ref.current.state.scoreRangeText !== ""){
		scoreRanges.push(scoreRange2Ref.current.state);											
	}
	if(scoreRange3Ref.current !== null && scoreRange3Ref.current.state.scoreRangeFrom !== "" && scoreRange3Ref.current.state.scoreRangeTo !== ""
													 && scoreRange3Ref.current.state.scoreRangeText !== ""){
		scoreRanges.push(scoreRange3Ref.current.state);											
	}
	if(scoreRange4Ref.current !== null && scoreRange4Ref.current.state.scoreRangeFrom !== "" && scoreRange4Ref.current.state.scoreRangeTo !== ""
													 && scoreRange4Ref.current.state.scoreRangeText !== ""){
		scoreRanges.push(scoreRange4Ref.current.state);											
	}
	if(scoreRange5Ref.current !== null && scoreRange5Ref.current.state.scoreRangeFrom !== "" && scoreRange5Ref.current.state.scoreRangeTo !== ""
													 && scoreRange5Ref.current.state.scoreRangeText !== ""){
		scoreRanges.push(scoreRange5Ref.current.state);											
	}
	
	values.diagnosticScoreRange = scoreRanges;
	
      const entity = {
        ... diagnosticEntity,
        ...values
      };

      if (isNew) {
        props.createEntity(entity);
      } else {
        props.updateEntity(entity);
      }
    }
  };

  return (
    <div>
      <Row className="justify-content-center">
        <Col md="8">
          <h2 id="marpeWellbeingApp.diagnostic.home.createOrEditLabel">Create or edit a Diagnostic</h2>
        </Col>
      </Row>
      <Row className="justify-content-center">
        <Col md="8">
          {loading ? (
            <p>Loading...</p>
          ) : (
            <AvForm model={isNew ? {} : diagnosticEntity} onSubmit={saveEntity}>
              {!isNew ? (
                <AvGroup>
                  <Label for="diagnostic-id">ID</Label>
                  <AvInput id="diagnostic-id" type="text" className="form-control" name="id" required readOnly />
                </AvGroup>
              ) : null}
              <AvGroup>
                <Label id="titleLabel" for="diagnostic-title">
                  Title
                </Label>
                <AvField id="diagnostic-title" type="text" name="title" validate={{
                    required: {value: true, errorMessage: 'This field is required.'}
                  }} />
              </AvGroup>
              {showSelectImage && <AvGroup>
                <Label for="upload-img">
                  Image
                </Label>
                <DiagnosticImageUpload
                  imgData={imgData || diagnosticEntity.imageUrl}
                  processing={imgUploading}
                  onImageChange={onImageChange}
                  onImageDelete={onImageDelete}
                />
              </AvGroup>
			  }
              {showSummaryText && <AvGroup>
				<Label id="blogText" for="blog-post-text">
                  Summary Text 
                </Label>
				{/*
				<ReactQuill key={diagnosticEntity.id} theme="snow" defaultValue={diagnosticEntity.summaryText} onChange={setTextAreaValue} modules={{toolbar: toolbarOptions}} readOnly={false}/>
			  	*/}
				 <AvInput 
					type="textarea" 
					name="summaryText" 
					id="diagnostic-summary-text"
					validate={{
						required: {value: true, errorMessage: 'This field is required.'},
						maxLength: {value: 2000, errorMessage: 'This field cannot be longer than 2000 characters.'}
					}}
					 />
				</AvGroup>
			  }
              <Button color="primary" id="add-questions" onClick={onAddQuestionClick}>
                <FontAwesomeIcon icon="save" />
                &nbsp; Add Questions
              </Button> 
			 <hr/>
				{
					 
					 (diagnosticQuestions != null && diagnosticQuestions !== undefined) &&
					 diagnosticQuestions.map(item => <DiagnosticQuestionsUpdate ref={diagnosticQuestionsRefMap[item['id']]} key={item['id']} id={item['id']} questionName={item['questionName']} diagnosticQuestionOptions={item['diagnosticQuestionOptions']} />)
				} 
              <hr/>
			  		<Row className="justify-content-center">
						<Col md="2">
							<Label> Score From </Label>
						</Col>
						
						<Col md="2">
							<Label> Score To </Label>
						</Col>
						
						<Col md="8">
							<Label> Score Text </Label>
						</Col>
						
					  </Row>
					<DiagnosticScoreRangeUpdate ref={scoreRange1Ref} id="1" scoreFrom ={scoreRangeDetail1.scoreFrom} scoreTo = {scoreRangeDetail1.scoreTo} scoreText={scoreRangeDetail1.scoreText} />
					<DiagnosticScoreRangeUpdate ref={scoreRange2Ref} id="2" scoreFrom ={scoreRangeDetail2.scoreFrom} scoreTo = {scoreRangeDetail2.scoreTo} scoreText={scoreRangeDetail2.scoreText} />
					<DiagnosticScoreRangeUpdate ref={scoreRange3Ref} id="3" scoreFrom ={scoreRangeDetail3.scoreFrom} scoreTo = {scoreRangeDetail3.scoreTo} scoreText={scoreRangeDetail3.scoreText} />
					<DiagnosticScoreRangeUpdate ref={scoreRange4Ref} id="4" scoreFrom ={scoreRangeDetail4.scoreFrom} scoreTo = {scoreRangeDetail4.scoreTo} scoreText={scoreRangeDetail4.scoreText} />
					<DiagnosticScoreRangeUpdate ref={scoreRange5Ref} id="5" scoreFrom ={scoreRangeDetail5.scoreFrom} scoreTo = {scoreRangeDetail5.scoreTo} scoreText={scoreRangeDetail5.scoreText} /> 
	
			  <hr/>
				<AvGroup>
                <Label id="trendTitle" for="trend-title">
                  Trends page
                </Label>
                <AvField id="trend-title" type="select" name="trendsPage" validate={{
						required: {value: true, errorMessage: 'This field is required.'},
					}}>
					<option key="trend-yes" value="">Generate trends page (Yes/No)</option>
					<option key="trend-yes" value="Yes">Yes</option>
					<option key="trend-no" value="No">No</option>
				</AvField>
              </AvGroup>
				
				
			  <Button tag={Link} id="cancel-save" to="/diagnostic" replace color="info">
                <FontAwesomeIcon icon="arrow-left" />
                &nbsp;
                <span className="d-none d-md-inline">Back</span>
              </Button>
              &nbsp;
              <Button color="primary" id="save-entity" type="submit" disabled={updating}>
                <FontAwesomeIcon icon="save" />
                &nbsp; Save
              </Button>
            </AvForm>
          )}
        </Col>
      </Row>
    </div>
  );
};

const mapStateToProps = (storeState: IRootState) => ({
  diagnosticEntity: storeState.diagnostic.entity,
  loading: storeState.diagnostic.loading,
  updating: storeState.diagnostic.updating,
  updateSuccess: storeState.diagnostic.updateSuccess
});

const mapDispatchToProps = {
  getEntity,
  updateEntity,
  createEntity,
  reset
};

type StateProps = ReturnType<typeof mapStateToProps>;
type DispatchProps = typeof mapDispatchToProps;

export default connect(mapStateToProps, mapDispatchToProps)(DiagnosticUpdate);
