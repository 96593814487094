import React from 'react';
import {  Row, Col, Label } from 'reactstrap';
import { AvGroup,  AvField } from 'availity-reactstrap-validation';

export interface IDiagnosticScoreRange {
	id,
	scoreFrom,
	scoreTo,
	scoreText
	
}

class DiagnosticScoreRangeUpdate extends React.Component<IDiagnosticScoreRange, {}> {
	
	state = {
		id : this.props.id,
		scoreRangeFrom: this.props.scoreFrom,
		scoreRangeTo: this.props.scoreTo,
		scoreRangeText: this.props.scoreText
	};
	
	componentDidMount() {
		
	}

    onScoreFromChange = (e) => {
		 this.setState({ ...this.state, scoreRangeFrom: e.target.value});
	}

	onScoreToChange = (e) => {
		this.setState({ ...this.state, scoreRangeTo: e.target.value});
	}
	
	onScoreTextChange = (e) => {
		this.setState({ ...this.state, scoreRangeText: e.target.value});
	}
    

	render() {
		return (
		    <div>
		      <Row className="justify-content-center">
		        <Col md="2">
	         	  <AvGroup key={this.state.id+"scoreFromP"}>
	                <AvField key={this.state.id+"scoreFrom"} id={this.state.id+"score-from"} placeholder="Score From" type="number" name={this.state.id+"score-from"} value={this.state.scoreRangeFrom} onChange={this.onScoreFromChange}  />
	              </AvGroup>
		        </Col>
				<Col md="2">
	         	  <AvGroup key={this.state.id+"scoreToP"}>
	                <AvField key={this.state.id+"scoreTo"} id={this.state.id+"score-to"} placeholder="Score To" type="number" name={this.state.id+"score-to"} value={this.state.scoreRangeTo} onChange={this.onScoreToChange}  />
	              </AvGroup>
		        </Col>
				<Col md="8">
	         	  <AvGroup key={this.state.id+"scoreTextp"}>
	                <AvField key={this.state.id+"scoreText"} id={this.state.id+"score-text"} placeholder="Score Text" type="text" name={this.state.id+"score-text"} value={this.state.scoreRangeText} onChange={this.onScoreTextChange}  />
	              </AvGroup>
		        </Col>
		      </Row>
		    </div>
  );
	}
	
}

export default DiagnosticScoreRangeUpdate;
