import axios from 'axios';
import { ICrudDeleteAction, ICrudGetAction, ICrudGetAllAction } from 'react-jhipster';
import { FAILURE, REQUEST, SUCCESS } from 'app/shared/reducers/action-type.util';

import { defaultValue, IInterestExpression } from 'app/shared/model/interest-expression.model';

export const ACTION_TYPES = {
  FETCH_INTERESTEXPRESSION_LIST: 'interestExpression/FETCH_INTERESTEXPRESSION_LIST',
  FETCH_INTERESTEXPRESSION: 'interestExpression/FETCH_INTERESTEXPRESSION',
  DELETE_INTERESTEXPRESSION: 'interestExpression/DELETE_INTERESTEXPRESSION',
  RESET: 'interestExpression/RESET'
};

const initialState = {
  loading: false,
  errorMessage: null,
  entities: [] as ReadonlyArray<IInterestExpression>,
  entity: defaultValue,
  updating: false,
  totalItems: 0,
  updateSuccess: false
};

export type InterestExpressionState = Readonly<typeof initialState>;

// Reducer

export default (state: InterestExpressionState = initialState, action): InterestExpressionState => {
  switch (action.type) {
    case REQUEST(ACTION_TYPES.FETCH_INTERESTEXPRESSION_LIST):
    case REQUEST(ACTION_TYPES.FETCH_INTERESTEXPRESSION):
      return {
        ...state,
        errorMessage: null,
        updateSuccess: false,
        loading: true
      };
    case REQUEST(ACTION_TYPES.DELETE_INTERESTEXPRESSION):
      return {
        ...state,
        errorMessage: null,
        updateSuccess: false,
        updating: true
      };
    case FAILURE(ACTION_TYPES.FETCH_INTERESTEXPRESSION_LIST):
    case FAILURE(ACTION_TYPES.FETCH_INTERESTEXPRESSION):
    case FAILURE(ACTION_TYPES.DELETE_INTERESTEXPRESSION):
      return {
        ...state,
        loading: false,
        updating: false,
        updateSuccess: false,
        errorMessage: action.payload
      };
    case SUCCESS(ACTION_TYPES.FETCH_INTERESTEXPRESSION_LIST):
      return {
        ...state,
        loading: false,
        entities: action.payload.data,
        totalItems: parseInt(action.payload.headers['x-total-count'], 10)
      };
    case SUCCESS(ACTION_TYPES.FETCH_INTERESTEXPRESSION):
      return {
        ...state,
        loading: false,
        entity: action.payload.data
      };
    case SUCCESS(ACTION_TYPES.DELETE_INTERESTEXPRESSION):
      return {
        ...state,
        updating: false,
        updateSuccess: true,
        entity: {}
      };
    case ACTION_TYPES.RESET:
      return {
        ...initialState
      };
    default:
      return state;
  }
};

const apiUrl = 'api/interest-expressions';

// Actions

export const getEntities: ICrudGetAllAction<IInterestExpression> = (page, size, sort) => {
  const requestUrl = `${apiUrl}${sort ? `?page=${page}&size=${size}&sort=${sort}` : ''}`;
  return {
    type: ACTION_TYPES.FETCH_INTERESTEXPRESSION_LIST,
    payload: axios.get<IInterestExpression>(`${requestUrl}${sort ? '&' : '?'}cacheBuster=${new Date().getTime()}`)
  };
};

export const getEntity: ICrudGetAction<IInterestExpression> = id => {
  const requestUrl = `${apiUrl}/${id}`;
  return {
    type: ACTION_TYPES.FETCH_INTERESTEXPRESSION,
    payload: axios.get<IInterestExpression>(requestUrl)
  };
};

export const deleteEntity: ICrudDeleteAction<IInterestExpression> = id => async dispatch => {
  const requestUrl = `${apiUrl}/${id}`;
  const result = await dispatch({
    type: ACTION_TYPES.DELETE_INTERESTEXPRESSION,
    payload: axios.delete(requestUrl)
  });
  return result;
};

export const reset = () => ({
  type: ACTION_TYPES.RESET
});
