import React, {useEffect} from 'react';
import {connect} from 'react-redux';
import {Card, Table} from 'reactstrap';

import {IRootState} from 'app/shared/reducers';
import {getEntities} from './therapist-verification-document.reducer';

import DocumentEntity from './document-entity';

export interface IDocumentsTableProps extends StateProps, DispatchProps {
  therapistId: string;
}

export const DocumentsTable = (props: IDocumentsTableProps) => {
  const getAllEntities = () => {
    props.getEntities(props.therapistId);
  };

  useEffect(() => {
    getAllEntities();
  }, []);

  const { therapistDocumentList, loading } = props;

  return (
    <Card style={{ backgroundColor: '#dfeef5' }}>
      <h6>Therapist verification documents</h6>
      <div className="table-responsive">
        {loading ? (
          <div>Loading...</div>
        ) : therapistDocumentList && therapistDocumentList.length > 0 ? (
          <Table responsive>
            <thead>
              <tr>
                <th>ID</th>
                <th>Title</th>
                <th>Validity From</th>
                <th>Validity To</th>
                <th>Is Valid</th>
                <th>Is Current</th>
                <th>Expires In A Few Days</th>
                <th>Type</th>
              </tr>
            </thead>
            <tbody>
              {therapistDocumentList.map((therapistDocument, i) => (
                <DocumentEntity therapistDocument={therapistDocument} key={`entity-${i}`} />
              ))}
            </tbody>
          </Table>
        ) : (
          <div className="alert alert-warning">No Verification Documents found for this therapist</div>
        )}
      </div>
    </Card>
  );
};

const mapStateToProps = ({ therapistVerificationDocument }: IRootState) => ({
  therapistDocumentList: therapistVerificationDocument.entities,
  loading: therapistVerificationDocument.loading
});

const mapDispatchToProps = {
  getEntities
};

type StateProps = ReturnType<typeof mapStateToProps>;
type DispatchProps = typeof mapDispatchToProps;

export default connect(mapStateToProps, mapDispatchToProps)(DocumentsTable);
