import { IAppointment } from 'app/shared/model/appointment.model';
import { IUser } from 'app/shared/model/user.model';
export interface IClient {
  id?: number;
  firstName?: string;
  lastName?: string;
  email?: string;
  address1?: string;
  address2?: string;
  imageS3Key?: string;
  gp?: string;
  activationKey?: string;
  postalCode?: string;
  phone?: string;
  user?: IUser;
  appointments?: IAppointment[];
}

export const defaultValue: Readonly<IClient> = {};
