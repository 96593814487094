import React, {useEffect} from 'react';
import {connect} from 'react-redux';
import {Link, RouteComponentProps} from 'react-router-dom';
import {Button, Col, Row} from 'reactstrap';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';

import {IRootState} from 'app/shared/reducers';
import {getEntity} from './client.reducer';

export interface IClientDetailProps extends StateProps, DispatchProps, RouteComponentProps<{ id: string }> {}

export const ClientDetail = (props: IClientDetailProps) => {
  useEffect(() => {
    props.getEntity(props.match.params.id);
  }, []);

  const { clientEntity } = props;
  return (
    <Row>
      <Col md="8">
        <h2>
          Client [<b>{clientEntity.id}</b>]
        </h2>
        <dl className="jh-entity-details">
          <dt>
            <span id="firstName">First Name</span>
          </dt>
          <dd>{clientEntity.firstName}</dd>
          <dt>
            <span id="lastName">Last Name</span>
          </dt>
          <dd>{clientEntity.lastName}</dd>
          <dt>
            <span id="email">Email</span>
          </dt>
          <dd>{clientEntity.email}</dd>
          <dt>
            <span id="address1">Address 1</span>
          </dt>
          <dd>{clientEntity.address1}</dd>
          <dt>
            <span id="address2">Address 2</span>
          </dt>
          <dd>{clientEntity.address2}</dd>
          <dt>
            <span id="imageS3Key">Image S 3 Key</span>
          </dt>
          <dd>{clientEntity.imageS3Key}</dd>
          <dt>
            <span id="gp">Gp</span>
          </dt>
          <dd>{clientEntity.gp}</dd>
          <dt>
            <span id="activationKey">Activation Key</span>
          </dt>
          <dd>{clientEntity.activationKey}</dd>
          <dt>
            <span id="postalCode">Postal Code</span>
          </dt>
          <dd>{clientEntity.postalCode}</dd>
          <dt>
            <span id="phone">Phone</span>
          </dt>
          <dd>{clientEntity.phone}</dd>
        </dl>
        <Button tag={Link} to="/client" replace color="info">
          <FontAwesomeIcon icon="arrow-left" /> <span className="d-none d-md-inline">Back</span>
        </Button>
        &nbsp;
        <Button tag={Link} to={`/client/${clientEntity.id}/edit`} replace color="primary">
          <FontAwesomeIcon icon="pencil-alt" /> <span className="d-none d-md-inline">Edit</span>
        </Button>
      </Col>
    </Row>
  );
};

const mapStateToProps = ({ client }: IRootState) => ({
  clientEntity: client.entity
});

const mapDispatchToProps = { getEntity };

type StateProps = ReturnType<typeof mapStateToProps>;
type DispatchProps = typeof mapDispatchToProps;

export default connect(mapStateToProps, mapDispatchToProps)(ClientDetail);
