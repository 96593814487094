import React, {useEffect, useState} from 'react';
import {connect} from 'react-redux';
import {Link, RouteComponentProps} from 'react-router-dom';
import {Button, Col, Label, Row} from 'reactstrap';
import {AvField, AvForm, AvGroup, AvInput} from 'availity-reactstrap-validation';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {IRootState} from 'app/shared/reducers';
import {getEntities as getClients} from 'app/entities/client/client.reducer';
import {getEntities as getTherapists} from 'app/entities/therapist/therapist.reducer';
import {createEntity, getEntity, reset, updateEntity} from './appointment.reducer';
import {convertDateTimeFromServer, convertDateTimeToServer, displayDefaultDateTime} from 'app/shared/util/date-utils';

export interface IAppointmentUpdateProps extends StateProps, DispatchProps, RouteComponentProps<{ id: string }> {}

export const AppointmentUpdate = (props: IAppointmentUpdateProps) => {
  const [clientId, setClientId] = useState('0');
  const [therapistId, setTherapistId] = useState('0');
  const [isNew, setIsNew] = useState(!props.match.params || !props.match.params.id);

  const { appointmentEntity, clients, therapists, loading, updating } = props;

  const handleClose = () => {
    props.history.push('/appointment' + props.location.search);
  };

  useEffect(() => {
    if (isNew) {
      props.reset();
    } else {
      props.getEntity(props.match.params.id);
    }

    props.getClients();
    props.getTherapists();
  }, []);

  useEffect(() => {
    if (props.updateSuccess) {
      handleClose();
    }
  }, [props.updateSuccess]);

  const saveEntity = (event, errors, values) => {
    values.startTime = convertDateTimeToServer(values.startTime);
    values.endTime = convertDateTimeToServer(values.endTime);

    if (errors.length === 0) {
      const entity = {
        ...appointmentEntity,
        ...values
      };

      if (isNew) {
        props.createEntity(entity);
      } else {
        props.updateEntity(entity);
      }
    }
  };

  return (
    <div>
      <Row className="justify-content-center">
        <Col md="8">
          <h2 id="marpeWellbeingApp.appointment.home.createOrEditLabel">Create or edit a Appointment</h2>
        </Col>
      </Row>
      <Row className="justify-content-center">
        <Col md="8">
          {loading ? (
            <p>Loading...</p>
          ) : (
            <AvForm model={isNew ? {} : appointmentEntity} onSubmit={saveEntity}>
              {!isNew ? (
                <AvGroup>
                  <Label for="appointment-id">ID</Label>
                  <AvInput id="appointment-id" type="text" className="form-control" name="id" required readOnly />
                </AvGroup>
              ) : null}
              <AvGroup>
                <Label id="statusLabel" for="appointment-status">
                  Status
                </Label>
                <AvInput
                  id="appointment-status"
                  type="select"
                  className="form-control"
                  name="status"
                  value={(!isNew && appointmentEntity.status) || 'SCHEDULED'}
                >
                  <option value="SCHEDULED">SCHEDULED</option>
                  <option value="CANCELED">CANCELED</option>
                  <option value="PASSED">PASSED</option>
                </AvInput>
              </AvGroup>
              <AvGroup>
                <Label id="commentLabel" for="appointment-comment">
                  Comment
                </Label>
                <AvField
                  id="appointment-comment"
                  type="text"
                  name="comment"
                  validate={{
                    maxLength: { value: 1024, errorMessage: 'This field cannot be longer than 1024 characters.' }
                  }}
                />
              </AvGroup>
              <AvGroup>
                <Label id="ratingLabel" for="appointment-rating">
                  Rating
                </Label>
                <AvField
                  id="appointment-rating"
                  type="string"
                  className="form-control"
                  name="rating"
                  validate={{
                    min: { value: 1, errorMessage: 'This field should be at least 1.' },
                    max: { value: 5, errorMessage: 'This field cannot be more than 5.' },
                    number: { value: true, errorMessage: 'This field should be a number.' }
                  }}
                />
              </AvGroup>
              <AvGroup>
                <Label id="startTimeLabel" for="appointment-startTime">
                  Start Time
                </Label>
                <AvInput
                  id="appointment-startTime"
                  type="datetime-local"
                  className="form-control"
                  name="startTime"
                  placeholder={'YYYY-MM-DD HH:mm'}
                  value={isNew ? displayDefaultDateTime() : convertDateTimeFromServer(props.appointmentEntity.startTime)}
                />
              </AvGroup>
              <AvGroup>
                <Label id="endTimeLabel" for="appointment-endTime">
                  End Time
                </Label>
                <AvInput
                  id="appointment-endTime"
                  type="datetime-local"
                  className="form-control"
                  name="endTime"
                  placeholder={'YYYY-MM-DD HH:mm'}
                  value={isNew ? displayDefaultDateTime() : convertDateTimeFromServer(props.appointmentEntity.endTime)}
                />
              </AvGroup>
              <AvGroup>
                <Label id="roomLabel" for="appointment-room">
                  Room
                </Label>
                <AvField id="appointment-room" type="text" name="room" />
              </AvGroup>
              <AvGroup>
                <Label for="appointment-client">Client</Label>
                <AvInput id="appointment-client" type="select" className="form-control" name="clientId">
                  <option value="" key="0" />
                  {clients
                    ? clients.map(otherEntity => (
                        <option value={otherEntity.id} key={otherEntity.id}>
                          {otherEntity.id}
                        </option>
                      ))
                    : null}
                </AvInput>
              </AvGroup>
              <AvGroup>
                <Label for="appointment-therapist">Therapist</Label>
                <AvInput id="appointment-therapist" type="select" className="form-control" name="therapistId">
                  <option value="" key="0" />
                  {therapists
                    ? therapists.map(otherEntity => (
                        <option value={otherEntity.id} key={otherEntity.id}>
                          {otherEntity.id}
                        </option>
                      ))
                    : null}
                </AvInput>
              </AvGroup>
              <Button tag={Link} id="cancel-save" to="/appointment" replace color="info">
                <FontAwesomeIcon icon="arrow-left" />
                &nbsp;
                <span className="d-none d-md-inline">Back</span>
              </Button>
              &nbsp;
              <Button color="primary" id="save-entity" type="submit" disabled={updating}>
                <FontAwesomeIcon icon="save" />
                &nbsp; Save
              </Button>
            </AvForm>
          )}
        </Col>
      </Row>
    </div>
  );
};

const mapStateToProps = (storeState: IRootState) => ({
  clients: storeState.client.entities,
  therapists: storeState.therapist.entities,
  appointmentEntity: storeState.appointment.entity,
  loading: storeState.appointment.loading,
  updating: storeState.appointment.updating,
  updateSuccess: storeState.appointment.updateSuccess
});

const mapDispatchToProps = {
  getClients,
  getTherapists,
  getEntity,
  updateEntity,
  createEntity,
  reset
};

type StateProps = ReturnType<typeof mapStateToProps>;
type DispatchProps = typeof mapDispatchToProps;

export default connect(mapStateToProps, mapDispatchToProps)(AppointmentUpdate);
