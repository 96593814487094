/* eslint-disable @typescript-eslint/camelcase */
import React, { useEffect, useState } from 'react';
import { useStripe } from '@stripe/react-stripe-js';
import { Link } from 'react-router-dom';
import { Button, Col, Label, Row } from 'reactstrap';
import { AvField, AvForm, AvGroup, AvInput } from 'availity-reactstrap-validation';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Autocomplete from 'react-autocomplete/build/lib/index'
import ChipInput from 'app/entities/therapist/components/ChipInput';
import { STATES_CONST } from 'app/entities/therapist/components/states';
import './styles.css';
import get from 'lodash/get';

const TherapistUpdate = (props) => {
  const [isNew, setIsNew] = useState(!props.match.params || !props.match.params.id);
  const [stateValue, setStateValue] = useState('');
  const [chipsData, setChipsData] = useState([]);
  const stripe = useStripe();
  const { therapistEntity, loading, updating, getPostCodes, postCodes } = props;
  const specFormat = get(therapistEntity, 'specializations') ? therapistEntity.specializations.split(',') : [];
  const handleClose = () => {
    props.history.push('/therapist' + props.location.search);
  };

  useEffect(() => {
    if (isNew) {
      props.reset();
    } else {
      props.getEntity(props.match.params.id);
    }
  }, []);
  useEffect(() => {
    setChipsData(specFormat);
  }, [therapistEntity])

  const createStripeAccount =  async ({sortCode, accountNumber, firstName, lastName}) => {
    const { error, token } = await stripe.createToken('bank_account', {
      country: 'GB',
      currency: 'GBP',
      routing_number: sortCode,
      account_number: accountNumber,
      account_holder_name: `${firstName} ${lastName}`,
      account_holder_type: 'individual',
    });
    if (error && !token) {
      console.error(error.message);
    } else {
      return { ...token, accountNumber }
    }
  }
  const saveEntity = async (event, values) => {
      const entityUpdate = {
        ...therapistEntity,
        ...values
      };
      if (isNew) {
        await createStripeAccount({...values}).then(res => {
          const entity = {
            ...therapistEntity,
            ...values,
            tokenExternalAccount: res.id || 'empty token',
            accountName: res.bank_account.account_holder_name,
            accountNumber: res.accountNumber,
            sortCode: res.bank_account.routing_number,
            specializations: chipsData.toString(),
            postalCode: stateValue,
          };
          props.createEntity(entity).then(() => handleClose()).catch((err) => console.error(err));
        }).catch((err)  => console.error(err));
      } else {
        props.updateEntity(entityUpdate);
      }
  };

  return (
    <div>
      <Row className="justify-content-center">
        <Col md="8">
          <h2 id="marpeWellbeingApp.therapist.home.createOrEditLabel">Create or edit a Therapist</h2>
        </Col>
      </Row>
      <Row className="justify-content-center">
        <Col md="8">
          {loading ? (
            <p>Loading...</p>
          ) : (
            <AvForm model={isNew ? {} : therapistEntity} onValidSubmit={saveEntity}>
              {!isNew ? (
                <AvGroup>
                  <Label for="therapist-id">ID</Label>
                  <AvInput id="therapist-id" type="text" className="form-control" name="id" required readOnly />
                </AvGroup>
              ) : null}
              <AvGroup>
                <Label id="firstNameLabel" for="therapist-firstName">
                  First Name
                </Label>
                <AvField
                  id="therapist-firstName"
                  type="text"
                  name="firstName"
                  validate={{
                    maxLength: { value: 100, errorMessage: 'This field cannot be longer than 100 characters.' }
                  }}
                />
              </AvGroup>
              <AvGroup>
                <Label id="lastNameLabel" for="therapist-lastName">
                  Last Name
                </Label>
                <AvField
                  id="therapist-lastName"
                  type="text"
                  name="lastName"
                  validate={{
                    maxLength: { value: 100, errorMessage: 'This field cannot be longer than 100 characters.' }
                  }}
                />
              </AvGroup>
              <AvGroup>
                <Label id="emailLabel" for="therapist-email">
                  Email
                </Label>
                <AvField
                  id="therapist-email"
                  type="text"
                  name="email"
                  validate={{
                    required: { value: true, errorMessage: 'This field is required.' },
                    maxLength: { value: 255, errorMessage: 'This field cannot be longer than 255 characters.' }
                  }}
                />
              </AvGroup>
              <AvGroup>
                <Label id="address1Label" for="therapist-address1">
                  Address 1
                </Label>
                <AvField
                  id="therapist-address1"
                  type="text"
                  name="address1"
                  validate={{
                    maxLength: { value: 1024, errorMessage: 'This field cannot be longer than 1024 characters.' }
                  }}
                />
              </AvGroup>
              <AvGroup>
                <Label id="address2Label" for="therapist-address2">
                  Address 2
                </Label>
                <AvField
                  id="therapist-address2"
                  type="text"
                  name="address2"
                  validate={{
                    maxLength: { value: 1024, errorMessage: 'This field cannot be longer than 1024 characters.' }
                  }}
                />
              </AvGroup>
              <Label id="stateLabel" for="therapist-state">
                Postal Code
              </Label>
              <div className="postCodeWrapper">
                <Autocomplete
                  getItemValue={(item) => item}
                  items={postCodes}
                  renderMenu={children => (
                    <div style={{width: '100%'}}>
                      {children}
                    </div>
                  )}
                  renderItem={(item, isHighlighted) =>
                    <div key={item} style={{ background: isHighlighted ? 'lightgray' : 'white' }}>
                      {item}
                    </div>
                  }
                  value={stateValue || therapistEntity.postalCode}
                  onChange={(e) => {
                    getPostCodes(e.target.value);
                    setStateValue(e.target.value)
                  }}
                  onSelect={(val) => setStateValue(val)}
                />
              </div>
              <AvGroup>
                <Label id="cityLabel" for="therapist-city">
                  City
                </Label>
                <AvField
                  id="therapist-city"
                  type="text"
                  name="city"
                  validate={{
                    maxLength: { value: 50, errorMessage: 'This field cannot be longer than 50 characters.' }
                  }}
                />
              </AvGroup>
              <AvGroup>
                <Label id="stateLabel" for="therapist-state">
                  State
                </Label>
                <AvField
                  id="therapist-state"
                  type="select"
                  name="state"
                  validate={{
                    maxLength: { value: 3, errorMessage: 'This field cannot be longer than 3 characters.' }
                  }}
                >
                  {STATES_CONST.map((el) => (
                    <option key={el.code} value={el.code}>{el.name}</option>
                    ))}
                </AvField>
              </AvGroup>
              <AvGroup>
                <Label id="dateOfBirthLabel" for="therapist-dateOfBirth">
                  Date Of Birth
                </Label>
                <AvField id="therapist-dateOfBirth" type="date" className="form-control" name="dateOfBirth" />
              </AvGroup>
              <AvGroup>
                <Label id="descriptionLabel" for="therapist-description">
                  Description
                </Label>
                <AvField
                  id="therapist-description"
                  type="text"
                  name="description"
                  validate={{
                    maxLength: { value: 1024, errorMessage: 'This field cannot be longer than 1024 characters.' }
                  }}
                />
              </AvGroup>
              <div className="marginBottomCustom">
                <Label id="specializationsLabel" for="therapist-specializations">
                  Specializations
                </Label>
                <ChipInput className="error" setChipsData={setChipsData} chipsData={chipsData} />
              </div>
              <AvGroup>
                <Label id="statusLabel" for="therapist-status">
                  Status
                </Label>
                <AvInput
                  id="therapist-status"
                  type="select"
                  className="form-control"
                  name="status"
                  value={(!isNew && therapistEntity.status) || 'NEW'}
                >
                  <option value="NEW">NEW</option>
                  <option value="APPROVED">APPROVED</option>
                  <option value="REJECTED">REJECTED</option>
                  <option value="DISCONTINUED">DISCONTINUED</option>
                </AvInput>
              </AvGroup>
              <AvGroup>
                <Label id="jobRoleLabel" for="therapist-jobRole">
                  Job Role
                </Label>
                <AvInput
                  id="therapist-jobRole"
                  type="select"
                  className="form-control"
                  name="jobRole"
                  value={(!isNew && therapistEntity.jobRole) || 'COUNSELLOR_PSYCHOTHERAPIST'}
                >
                  <option value="COUNSELLOR_PSYCHOTHERAPIST">COUNSELLOR_PSYCHOTHERAPIST</option>
                  <option value="CLINICAL_PSYCHOLOGIST">CLINICAL_PSYCHOLOGIST</option>
                </AvInput>
              </AvGroup>
              <AvGroup>
                <Label id="phoneLabel" for="therapist-phone">
                  Phone
                </Label>
                <AvField
                  id="therapist-phone"
                  type="text"
                  name="phone"
                  validate={{
                    maxLength: { value: 13, errorMessage: 'This field cannot be longer than 13 characters.' },
                    pattern: {
                      value: '^\\+44\\d{10}$',
                      errorMessage: "This field should follow pattern for '^\\+44\\d{10}$'"
                    }
                  }}
                />
              </AvGroup>
              <AvGroup>
                <Label id="accountNumber" for="account-number">
                  Account Number
                </Label>
                <AvField
                  id="account-number"
                  type="string"
                  className="form-control"
                  name="accountNumber"
                  validate={{
                    required: { value: true, errorMessage: 'This field is required.' },
                  }}
                />
              </AvGroup>
              <AvGroup>
                <Label id="accountName" for="account-name">
                  Account Name
                </Label>
                <AvField
                  id="account-name"
                  type="string"
                  className="form-control"
                  name="accountName"
                  validate={{
                    required: { value: true, errorMessage: 'This field is required.' },
                  }}
                />
              </AvGroup>
              <AvGroup>
                <Label id="sortCode" for="sort-code">
                  Sort Code
                </Label>
                <AvField
                  id="sortCode"
                  type="string"
                  className="form-control"
                  name="sortCode"
                  validate={{
                    required: { value: true, errorMessage: 'This field is required.' },
                  }}
                />
              </AvGroup>
              <Button tag={Link} id="cancel-save" to="/therapist" replace color="info">
                <FontAwesomeIcon icon="arrow-left" />
                &nbsp;
                <span className="d-none d-md-inline">Back</span>
              </Button>
              &nbsp;
              <Button color="primary" id="save-entity" type="submit">
                <FontAwesomeIcon icon="save" />
                &nbsp; Save
              </Button>
            </AvForm>
          )}
        </Col>
      </Row>
    </div>
  );
};

export default TherapistUpdate;
