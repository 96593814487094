
export interface IMoodTracker {
  userName?: string;
  email?: string;
  moodDate?: string;
  phone?: string;
  mood?: string;
  sleep?: string;
  energy?: string;
  motivation?: string;
  anxious?: string;
}

export const defaultValue: Readonly<IMoodTracker> = {};
