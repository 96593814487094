import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { Link, RouteComponentProps } from 'react-router-dom';
import { Button, Row, Col } from 'reactstrap';
import { ICrudGetAction, TextFormat } from 'react-jhipster';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { IRootState } from 'app/shared/reducers';
import { getEntity } from './diagnostic.reducer';
import { IDiagnostic } from 'app/shared/model/diagnostic.model';
import { APP_DATE_FORMAT, APP_LOCAL_DATE_FORMAT } from 'app/config/constants';

export interface IDiagnosticDetailProps extends StateProps, DispatchProps, RouteComponentProps<{ id: string }> {}

export const DiagnosticDetail = (props: IDiagnosticDetailProps) => {
  useEffect(() => {
    props.getEntity(props.match.params.id);
  }, []);

  const { diagnosticEntity } = props;
  return (
    <Row>
      <Col md="8">
        <h2>
          Diagnostic [<b>{diagnosticEntity.id}</b>]
        </h2>
        <dl className="jh-entity-details">
          <dt>
            <span id="title">Title</span>
          </dt>
          <dd>{diagnosticEntity.title}</dd>
          <dt>
            <span id="imageUrl">Image Url</span>
          </dt>
          <dd>{diagnosticEntity.imageUrl && <img style={{ width: "300px" }} src={diagnosticEntity.imageUrl} />}</dd>
          <dt>
            <span id="summaryText">Summary Text</span>
          </dt>
		  <dd dangerouslySetInnerHTML={{__html: diagnosticEntity.summaryText}}></dd>
		  <dt>
            <span id="trendsPage">Generate trends page</span>
          </dt>
          <dd>{diagnosticEntity.trendsPage}</dd>
        </dl>
        <Button tag={Link} to="/diagnostic" replace color="info">
          <FontAwesomeIcon icon="arrow-left" /> <span className="d-none d-md-inline">Back</span>
        </Button>
        &nbsp;
        <Button tag={Link} to={`/diagnostic/${diagnosticEntity.id}/edit`} replace color="primary">
          <FontAwesomeIcon icon="pencil-alt" /> <span className="d-none d-md-inline">Edit</span>
        </Button>
      </Col>
    </Row>
  );
};

const mapStateToProps = ({ diagnostic }: IRootState) => ({
  diagnosticEntity: diagnostic.entity
});

const mapDispatchToProps = { getEntity };

type StateProps = ReturnType<typeof mapStateToProps>;
type DispatchProps = typeof mapDispatchToProps;

export default connect(mapStateToProps, mapDispatchToProps)(DiagnosticDetail);
