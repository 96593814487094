import { Moment } from 'moment';

export interface IMoodTips {
  id?: number;
  tips?: string;
  userId?: number;
  createdDate?: Moment;
  updatedDate?: Moment;
}

export const defaultValue: Readonly<IMoodTips> = {};
