import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { Link, RouteComponentProps } from 'react-router-dom';
import { Button, Row, Col } from 'reactstrap';
import { ICrudGetAction, TextFormat } from 'react-jhipster';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { IRootState } from 'app/shared/reducers';
import { getEntity } from './notifications.reducer';
import { INotifications } from 'app/shared/model/notifications.model';
import { APP_DATE_FORMAT, APP_LOCAL_DATE_FORMAT } from 'app/config/constants';

export interface INotificationsDetailProps extends StateProps, DispatchProps, RouteComponentProps<{ id: string }> {}

export const NotificationsDetail = (props: INotificationsDetailProps) => {
  useEffect(() => {
    props.getEntity(props.match.params.id);
  }, []);

  const { notificationsEntity } = props;
  return (
    <Row>
      <Col md="8">
        <h2>
          Notifications [<b>{notificationsEntity.id}</b>]
        </h2>
        <dl className="jh-entity-details">
          <dt>
            <span id="title">Title</span>
          </dt>
          <dd>{notificationsEntity.title}</dd>
          <dt>
            <span id="description">Description</span>
          </dt>
          <dd>{notificationsEntity.description}</dd>
          <dt>
            <span id="updateTs">Update Ts</span>
          </dt>
          <dd>
            <TextFormat value={notificationsEntity.updateTs} type="date" format={APP_LOCAL_DATE_FORMAT} />
          </dd>
        </dl>
        <Button tag={Link} to="/notifications" replace color="info">
          <FontAwesomeIcon icon="arrow-left" /> <span className="d-none d-md-inline">Back</span>
        </Button>
        &nbsp;
        <Button tag={Link} to={`/notifications/${notificationsEntity.id}/edit`} replace color="primary">
          <FontAwesomeIcon icon="pencil-alt" /> <span className="d-none d-md-inline">Edit</span>
        </Button>
      </Col>
    </Row>
  );
};

const mapStateToProps = ({ notifications }: IRootState) => ({
  notificationsEntity: notifications.entity
});

const mapDispatchToProps = { getEntity };

type StateProps = ReturnType<typeof mapStateToProps>;
type DispatchProps = typeof mapDispatchToProps;

export default connect(mapStateToProps, mapDispatchToProps)(NotificationsDetail);
