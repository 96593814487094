import React, {useEffect} from 'react';
import {connect} from 'react-redux';
import {Link, RouteComponentProps} from 'react-router-dom';
import {Button, Col, Row} from 'reactstrap';
import {TextFormat} from 'react-jhipster';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';

import {IRootState} from 'app/shared/reducers';
import {getEntity} from './appointment.reducer';
import {APP_DATE_FORMAT} from 'app/config/constants';

export interface IAppointmentDetailProps extends StateProps, DispatchProps, RouteComponentProps<{ id: string }> {}

export const AppointmentDetail = (props: IAppointmentDetailProps) => {
  useEffect(() => {
    props.getEntity(props.match.params.id);
  }, []);

  const { appointmentEntity } = props;
  return (
    <Row>
      <Col md="8">
        <h2>
          Appointment [<b>{appointmentEntity.id}</b>]
        </h2>
        <dl className="jh-entity-details">
          <dt>
            <span id="status">Status</span>
          </dt>
          <dd>{appointmentEntity.status}</dd>
          <dt>
            <span id="comment">Comment</span>
          </dt>
          <dd>{appointmentEntity.comment}</dd>
          <dt>
            <span id="rating">Rating</span>
          </dt>
          <dd>{appointmentEntity.rating}</dd>
          <dt>
            <span id="startTime">Start Time</span>
          </dt>
          <dd>
            <TextFormat value={appointmentEntity.startTime} type="date" format={APP_DATE_FORMAT} />
          </dd>
          <dt>
            <span id="endTime">End Time</span>
          </dt>
          <dd>
            <TextFormat value={appointmentEntity.endTime} type="date" format={APP_DATE_FORMAT} />
          </dd>
          <dt>
            <span id="room">Room</span>
          </dt>
          <dd>{appointmentEntity.room}</dd>
          <dt>Client</dt>
          <dd>{appointmentEntity.clientId ? appointmentEntity.clientId : ''}</dd>
          <dt>Therapist</dt>
          <dd>{appointmentEntity.therapistId ? appointmentEntity.therapistId : ''}</dd>
        </dl>
        <Button tag={Link} to="/appointment" replace color="info">
          <FontAwesomeIcon icon="arrow-left" /> <span className="d-none d-md-inline">Back</span>
        </Button>
        &nbsp;
        <Button tag={Link} to={`/appointment/${appointmentEntity.id}/edit`} replace color="primary">
          <FontAwesomeIcon icon="pencil-alt" /> <span className="d-none d-md-inline">Edit</span>
        </Button>
      </Col>
    </Row>
  );
};

const mapStateToProps = ({ appointment }: IRootState) => ({
  appointmentEntity: appointment.entity
});

const mapDispatchToProps = { getEntity };

type StateProps = ReturnType<typeof mapStateToProps>;
type DispatchProps = typeof mapDispatchToProps;

export default connect(mapStateToProps, mapDispatchToProps)(AppointmentDetail);
