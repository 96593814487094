import axios from 'axios';
import { ICrudGetAction, ICrudGetAllAction, ICrudPutAction, ICrudDeleteAction } from 'react-jhipster';

import { cleanEntity } from 'app/shared/util/entity-utils';
import { REQUEST, SUCCESS, FAILURE } from 'app/shared/reducers/action-type.util';

import { IMoodTracker, defaultValue } from 'app/shared/model/mood-tracker.model';

export const ACTION_TYPES = {
  FETCH_MOODTRACKER_LIST: 'moodTips/FETCH_MOODTIPS_LIST',
  RESET: 'moodTips/RESET'
};

const initialState = {
  loading: false,
  errorMessage: null,
  entities: [] as ReadonlyArray<IMoodTracker>,
  entity: defaultValue,
  updating: false,
  totalItems: 0,
  updateSuccess: false
};

export type MoodTrackerState = Readonly<typeof initialState>;

// Reducer

export default (state: MoodTrackerState = initialState, action): MoodTrackerState => {
  switch (action.type) {
    case REQUEST(ACTION_TYPES.FETCH_MOODTRACKER_LIST):
      return {
        ...state,
        errorMessage: null,
        updateSuccess: false,
        loading: true
      };
    
    case FAILURE(ACTION_TYPES.FETCH_MOODTRACKER_LIST):
      return {
        ...state,
        loading: false,
        updating: false,
        updateSuccess: false,
        errorMessage: action.payload
      };
    case SUCCESS(ACTION_TYPES.FETCH_MOODTRACKER_LIST):
      return {
        ...state,
        loading: false,
        entities: action.payload.data,
        totalItems: parseInt(action.payload.headers['x-total-count'], 10)
      };
    case ACTION_TYPES.RESET:
      return {
        ...initialState
      };
    default:
      return state;
  }
};

const apiUrl = 'api/user-mood/view';

// Actions

export const getEntities: ICrudGetAllAction<IMoodTracker> = (page, size, sort) => {
  const requestUrl = `${apiUrl}${sort ? `?page=${page}&size=${size}&sort=${sort}` : ''}`;
  return {
    type: ACTION_TYPES.FETCH_MOODTRACKER_LIST,
    payload: axios.get<IMoodTracker>(`${requestUrl}${sort ? '&' : '?'}cacheBuster=${new Date().getTime()}`)
  };
};

export const reset = () => ({
  type: ACTION_TYPES.RESET
});
