import React, {useEffect, useState} from 'react';
import {connect} from 'react-redux';
import {Link, RouteComponentProps} from 'react-router-dom';
import {Button, Col, Label, Row} from 'reactstrap';
import {AvField, AvForm, AvGroup, AvInput} from 'availity-reactstrap-validation';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {IRootState} from 'app/shared/reducers';

import {getEntity, reset, updateEntity} from './price.reducer';

export interface IPriceUpdateProps extends StateProps, DispatchProps, RouteComponentProps<{ id: string }> {
}

export const PriceUpdate = (props: IPriceUpdateProps) => {
  const [isNew, setIsNew] = useState(!props.match.params || !props.match.params.id);

  const {priceEntity, loading, updating} = props;

  const handleClose = () => {
    props.history.push('/price');
  };

  useEffect(() => {
    if (isNew) {
      props.reset();
    } else {
      props.getEntity(props.match.params.id);
    }
  }, []);

  useEffect(() => {
    if (props.updateSuccess) {
      handleClose();
    }
  }, [props.updateSuccess]);

  const saveEntity = (event, errors, values) => {
    if (errors.length === 0) {
      const entity = {
        ...priceEntity,
        ...values
      };
      props.updateEntity(entity);
    }
  };

  return (
    <div>
      <Row className="justify-content-center">
        <Col md="8">
          <h2 id="marpeWellbeingApp.price.home.createOrEditLabel">Create or edit a Price</h2>
        </Col>
      </Row>
      <Row className="justify-content-center">
        <Col md="8">
          {loading ? (
            <p>Loading...</p>
          ) : (
            <AvForm model={isNew ? {} : priceEntity} onSubmit={saveEntity}>
              {!isNew ? (
                <AvGroup>
                  <Label for="price-id">ID</Label>
                  <AvInput id="price-id" type="text" className="form-control" name="id" required readOnly/>
                </AvGroup>
              ) : null}
              <AvGroup>
                <Label id="pricePer30MinLabel" for="price-pricePer30Min">
                  Price Per 30 Min
                </Label>
                <AvField
                  id="price-pricePer30Min"
                  type="string"
                  className="form-control"
                  name="pricePer30Min"
                  validate={{
                    required: {value: true, errorMessage: 'This field is required.'},
                    min: {value: 0, errorMessage: 'This field should be at least 0.'},
                    number: {value: true, errorMessage: 'This field should be a number.'}
                  }}
                />
              </AvGroup>
              <AvGroup>
                <Label id="commissionPercentagePer30MinLabel" for="price-commissionPercentagePer30Min">
                  Commission Percentage Per 30 Min
                </Label>
                <AvField
                  id="price-commissionPercentagePer30Min"
                  type="string"
                  className="form-control"
                  name="commissionPercentagePer30Min"
                  validate={{
                    required: {value: true, errorMessage: 'This field is required.'},
                    min: {value: 0, errorMessage: 'This field should be at least 0.'},
                    number: {value: true, errorMessage: 'This field should be a number.'}
                  }}
                />
              </AvGroup>
              <AvGroup>
                <Label id="pricePer50MinLabel" for="price-pricePer50Min">
                  Price Per 50 Min
                </Label>
                <AvField
                  id="price-pricePer50Min"
                  type="string"
                  className="form-control"
                  name="pricePer50Min"
                  validate={{
                    required: {value: true, errorMessage: 'This field is required.'},
                    min: {value: 0, errorMessage: 'This field should be at least 0.'},
                    number: {value: true, errorMessage: 'This field should be a number.'}
                  }}
                />
              </AvGroup>
              <AvGroup>
                <Label id="commissionPercentagePer50MinLabel" for="price-commissionPercentagePer50Min">
                  Commission Percentage Per 50 Min
                </Label>
                <AvField
                  id="price-commissionPercentagePer50Min"
                  type="string"
                  className="form-control"
                  name="commissionPercentagePer50Min"
                  validate={{
                    required: {value: true, errorMessage: 'This field is required.'},
                    min: {value: 0, errorMessage: 'This field should be at least 0.'},
                    number: {value: true, errorMessage: 'This field should be a number.'}
                  }}
                />
              </AvGroup>
              <Button tag={Link} id="cancel-save" to="/price" replace color="info">
                <FontAwesomeIcon icon="arrow-left"/>
                &nbsp;
                <span className="d-none d-md-inline">Back</span>
              </Button>
              &nbsp;
              <Button color="primary" id="save-entity" type="submit" disabled={updating}>
                <FontAwesomeIcon icon="save"/>
                &nbsp; Save
              </Button>
            </AvForm>
          )}
        </Col>
      </Row>
    </div>
  );
};

const mapStateToProps = (storeState: IRootState) => ({
  priceEntity: storeState.price.entity,
  loading: storeState.price.loading,
  updating: storeState.price.updating,
  updateSuccess: storeState.price.updateSuccess
});

const mapDispatchToProps = {
  getEntity,
  updateEntity,
  reset
};

type StateProps = ReturnType<typeof mapStateToProps>;
type DispatchProps = typeof mapDispatchToProps;

export default connect(mapStateToProps, mapDispatchToProps)(PriceUpdate);
