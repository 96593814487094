import React from 'react';
import { Switch } from 'react-router-dom';
import { Elements } from '@stripe/react-stripe-js';

import ErrorBoundaryRoute from 'app/shared/error/error-boundary-route';

import Therapist from './therapist';
import TherapistDetail from './therapist-detail';
import TherapistDeleteDialog from './therapist-delete-dialog';
import TherapistUpdateContainer from './therapist-update-container';

const Routes = ({ match }) => (
  <>
    <Switch>
      <ErrorBoundaryRoute exact path={`${match.url}/:id/delete`} component={TherapistDeleteDialog} />
      <ErrorBoundaryRoute exact path={`${match.url}/new`} component={TherapistUpdateContainer} />
      <ErrorBoundaryRoute exact path={`${match.url}/:id/edit`} component={TherapistUpdateContainer} />
      <ErrorBoundaryRoute exact path={`${match.url}/:id`} component={TherapistDetail} />
      <ErrorBoundaryRoute path={match.url} component={Therapist} />
    </Switch>
  </>
);

export default Routes;
