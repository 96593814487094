import axios from 'axios';
import { ICrudGetAction, ICrudPutAction } from 'react-jhipster';

import { FAILURE, REQUEST, SUCCESS } from 'app/shared/reducers/action-type.util';

import { defaultValue, IVerificationDocument, IVerificationDocumentValidityDates } from 'app/shared/model/verification-document.model';

export const ACTION_TYPES = {
  FETCH_THERAPIST_DOCUMENT_LIST: 'therapistVerificationDocument/FETCH_THERAPIST_DOCUMENT_LIST',
  SET_VALIDITY_DATES: 'therapistVerificationDocument/SET_VALIDITY_DATES',
  VALIDATE_VERIFICATION_DOCUMENT: 'therapistVerificationDocument/VALIDATE_VERIFICATION_DOCUMENT',
  REJECT_VERIFICATION_DOCUMENT: 'therapistVerificationDocument/REJECT_VERIFICATION_DOCUMENT',
  RESET: 'therapistVerificationDocument/RESET'
};

const initialState = {
  loading: false,
  errorMessage: null,
  entities: [] as ReadonlyArray<IVerificationDocument>,
  entity: defaultValue,
  updating: false,
  updateSuccess: false
};

export type TherapistVerificationDocumentState = Readonly<typeof initialState>;

// Reducer

export default (state: TherapistVerificationDocumentState = initialState, action): TherapistVerificationDocumentState => {
  switch (action.type) {
    case REQUEST(ACTION_TYPES.FETCH_THERAPIST_DOCUMENT_LIST):
      return {
        ...state,
        errorMessage: null,
        updateSuccess: false,
        loading: true
      };
    case REQUEST(ACTION_TYPES.SET_VALIDITY_DATES):
    case REQUEST(ACTION_TYPES.VALIDATE_VERIFICATION_DOCUMENT):
    case REQUEST(ACTION_TYPES.REJECT_VERIFICATION_DOCUMENT):
      return {
        ...state,
        errorMessage: null,
        updateSuccess: false,
        updating: true
      };
    case FAILURE(ACTION_TYPES.FETCH_THERAPIST_DOCUMENT_LIST):
    case FAILURE(ACTION_TYPES.SET_VALIDITY_DATES):
    case FAILURE(ACTION_TYPES.VALIDATE_VERIFICATION_DOCUMENT):
    case FAILURE(ACTION_TYPES.REJECT_VERIFICATION_DOCUMENT):
      return {
        ...state,
        loading: false,
        updating: false,
        updateSuccess: false,
        errorMessage: action.payload
      };
    case SUCCESS(ACTION_TYPES.FETCH_THERAPIST_DOCUMENT_LIST):
      return {
        ...state,
        loading: false,
        entities: action.payload.data
      };
    case SUCCESS(ACTION_TYPES.SET_VALIDITY_DATES):
    case SUCCESS(ACTION_TYPES.VALIDATE_VERIFICATION_DOCUMENT):
    case SUCCESS(ACTION_TYPES.REJECT_VERIFICATION_DOCUMENT):
      return {
        ...state,
        updating: false,
        updateSuccess: true,
        entity: action.payload.data
      };
    case ACTION_TYPES.RESET:
      return {
        ...initialState
      };
    default:
      return state;
  }
};

const therapistDocumentsApiUrl = (therapistId: number) => `api/therapists/${therapistId}/verification-documents`;
const setValidityDatesApiUrl = (documentId: number, validFrom: string, validTo: string) =>
  `/api/verification-documents/${documentId}?valid-from=${validFrom}&valid-to=${validTo}`;
const validateDocumentUrl = (documentId: number) => `/api/verification-documents/${documentId}/valid?is-valid=true`;
const rejectDocumentUrl = (documentId: number) => `/api/verification-documents/${documentId}/valid?is-valid=false`;

// Actions

export const getEntities: ICrudGetAction<IVerificationDocument> = (therapistId: number) => ({
  type: ACTION_TYPES.FETCH_THERAPIST_DOCUMENT_LIST,
  payload: axios.get<IVerificationDocument>(`${therapistDocumentsApiUrl(therapistId)}?cacheBuster=${new Date().getTime()}`)
});

export const setValidityDates: ICrudPutAction<IVerificationDocumentValidityDates> = entity => async dispatch => {
  const { id, therapistId, validityFrom, validityTo } = entity;
  const result = await dispatch({
    type: ACTION_TYPES.SET_VALIDITY_DATES,
    payload: axios.patch(setValidityDatesApiUrl(id, validityFrom, validityTo))
  });
  dispatch(getEntities(therapistId));
  return result;
};

export const validateVerificationDocument: ICrudPutAction<IVerificationDocument> = entity => async dispatch => {
  const { id, therapistId } = entity;
  const result = await dispatch({
    type: ACTION_TYPES.VALIDATE_VERIFICATION_DOCUMENT,
    payload: axios.patch(validateDocumentUrl(id))
  });
  dispatch(getEntities(therapistId));
  return result;
};

export const rejectVerificationDocument: ICrudPutAction<IVerificationDocument> = entity => async dispatch => {
  const { id, therapistId } = entity;
  const result = await dispatch({
    type: ACTION_TYPES.REJECT_VERIFICATION_DOCUMENT,
    payload: axios.patch(rejectDocumentUrl(id))
  });
  dispatch(getEntities(therapistId));
  return result;
};

export const reset = () => ({
  type: ACTION_TYPES.RESET
});
