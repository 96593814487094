import React, {useEffect, useState} from 'react';
import {connect} from 'react-redux';
import {Link, RouteComponentProps} from 'react-router-dom';
import {Button, Col, Label, Row} from 'reactstrap';
import {AvField, AvForm, AvGroup, AvInput} from 'availity-reactstrap-validation';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {IRootState} from 'app/shared/reducers';

import {createEntity, getEntity, reset, updateEntity} from './client.reducer';

export interface IClientUpdateProps extends StateProps, DispatchProps, RouteComponentProps<{ id: string }> {}

export const ClientUpdate = (props: IClientUpdateProps) => {
  const [isNew, setIsNew] = useState(!props.match.params || !props.match.params.id);

  const { clientEntity, loading, updating } = props;

  const handleClose = () => {
    props.history.push('/client' + props.location.search);
  };

  useEffect(() => {
    if (isNew) {
      props.reset();
    } else {
      props.getEntity(props.match.params.id);
    }
  }, []);

  useEffect(() => {
    if (props.updateSuccess) {
      handleClose();
    }
  }, [props.updateSuccess]);

  const saveEntity = (event, errors, values) => {
    if (errors.length === 0) {
      const entity = {
        ...clientEntity,
        ...values
      };

      if (isNew) {
        props.createEntity(entity);
      } else {
        props.updateEntity(entity);
      }
    }
  };

  return (
    <div>
      <Row className="justify-content-center">
        <Col md="8">
          <h2 id="marpeWellbeingApp.client.home.createOrEditLabel">Create or edit a Client</h2>
        </Col>
      </Row>
      <Row className="justify-content-center">
        <Col md="8">
          {loading ? (
            <p>Loading...</p>
          ) : (
            <AvForm model={isNew ? {} : clientEntity} onSubmit={saveEntity}>
              {!isNew ? (
                <AvGroup>
                  <Label for="client-id">ID</Label>
                  <AvInput id="client-id" type="text" className="form-control" name="id" required readOnly />
                </AvGroup>
              ) : null}
              <AvGroup>
                <Label id="firstNameLabel" for="client-firstName">
                  First Name
                </Label>
                <AvField
                  id="client-firstName"
                  type="text"
                  name="firstName"
                  validate={{
                    maxLength: { value: 100, errorMessage: 'This field cannot be longer than 100 characters.' }
                  }}
                />
              </AvGroup>
              <AvGroup>
                <Label id="lastNameLabel" for="client-lastName">
                  Last Name
                </Label>
                <AvField
                  id="client-lastName"
                  type="text"
                  name="lastName"
                  validate={{
                    maxLength: { value: 100, errorMessage: 'This field cannot be longer than 100 characters.' }
                  }}
                />
              </AvGroup>
              <AvGroup>
                <Label id="emailLabel" for="client-email">
                  Email
                </Label>
                <AvField
                  id="client-email"
                  type="text"
                  name="email"
                  validate={{
                    required: { value: true, errorMessage: 'This field is required.' },
                    maxLength: { value: 255, errorMessage: 'This field cannot be longer than 255 characters.' },
                    pattern: { value: '^\\S+@\\S+$', errorMessage: "This field should follow pattern for '^\\S+@\\S+.." }
                  }}
                />
              </AvGroup>
              <AvGroup>
                <Label id="address1Label" for="client-address1">
                  Address 1
                </Label>
                <AvField
                  id="client-address1"
                  type="text"
                  name="address1"
                  validate={{
                    maxLength: { value: 1024, errorMessage: 'This field cannot be longer than 1024 characters.' }
                  }}
                />
              </AvGroup>
              <AvGroup>
                <Label id="address2Label" for="client-address2">
                  Address 2
                </Label>
                <AvField
                  id="client-address2"
                  type="text"
                  name="address2"
                  validate={{
                    maxLength: { value: 1024, errorMessage: 'This field cannot be longer than 1024 characters.' }
                  }}
                />
              </AvGroup>
              <AvGroup>
                <Label id="imageS3KeyLabel" for="client-imageS3Key">
                  Image S 3 Key
                </Label>
                <AvField
                  id="client-imageS3Key"
                  type="text"
                  name="imageS3Key"
                  validate={{
                    maxLength: { value: 400, errorMessage: 'This field cannot be longer than 400 characters.' }
                  }}
                />
              </AvGroup>
              <AvGroup>
                <Label id="gpLabel" for="client-gp">
                  Gp
                </Label>
                <AvField
                  id="client-gp"
                  type="text"
                  name="gp"
                  validate={{
                    maxLength: { value: 4096, errorMessage: 'This field cannot be longer than 4096 characters.' }
                  }}
                />
              </AvGroup>
              <AvGroup>
                <Label id="activationKeyLabel" for="client-activationKey">
                  Activation Key
                </Label>
                <AvField
                  id="client-activationKey"
                  type="text"
                  name="activationKey"
                  validate={{
                    maxLength: { value: 20, errorMessage: 'This field cannot be longer than 20 characters.' }
                  }}
                />
              </AvGroup>
              <AvGroup>
                <Label id="postalCodeLabel" for="client-postalCode">
                  Postal Code
                </Label>
                <AvField
                  id="client-postalCode"
                  type="text"
                  name="postalCode"
                  validate={{
                    maxLength: { value: 8, errorMessage: 'This field cannot be longer than 8 characters.' }
                  }}
                />
              </AvGroup>
              <AvGroup>
                <Label id="phoneLabel" for="client-phone">
                  Phone
                </Label>
                <AvField
                  id="client-phone"
                  type="text"
                  name="phone"
                  validate={{
                    maxLength: { value: 11, errorMessage: 'This field cannot be longer than 11 characters.' },
                    pattern: {
                      value: '^\\(?0( *\\d\\)?){9,10}$',
                      errorMessage: "This field should follow pattern for '^\\(?0( *\\d\\)?){9,10}.."
                    }
                  }}
                />
              </AvGroup>
              <Button tag={Link} id="cancel-save" to="/client" replace color="info">
                <FontAwesomeIcon icon="arrow-left" />
                &nbsp;
                <span className="d-none d-md-inline">Back</span>
              </Button>
              &nbsp;
              <Button color="primary" id="save-entity" type="submit" disabled={updating}>
                <FontAwesomeIcon icon="save" />
                &nbsp; Save
              </Button>
            </AvForm>
          )}
        </Col>
      </Row>
    </div>
  );
};

const mapStateToProps = (storeState: IRootState) => ({
  clientEntity: storeState.client.entity,
  loading: storeState.client.loading,
  updating: storeState.client.updating,
  updateSuccess: storeState.client.updateSuccess
});

const mapDispatchToProps = {
  getEntity,
  updateEntity,
  createEntity,
  reset
};

type StateProps = ReturnType<typeof mapStateToProps>;
type DispatchProps = typeof mapDispatchToProps;

export default connect(mapStateToProps, mapDispatchToProps)(ClientUpdate);
